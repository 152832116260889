import React, {useCallback} from "react";
import select from 'bootstrap'
import styles from "./SelectInput.module.scss";

function SelectInput(props) {
    const {
        id,
        name = "",
        value,
        setValue,
        label,
        options,
        required = false,
        disabled = false,
    } = props;
    console.log(value)

    const handleChange = useCallback((event) => {
        if (setValue) {
            const value = event.target.value;
            const selectedOption = options.find(opt => opt.value === value);

            setValue({
                value,
                name: selectedOption.name,
                label: selectedOption.key,
                icon: selectedOption?.icon,
            });
        }
    }, [options]);

    const renderOptions = () => {
        if (!options) {
            return;
        }
        return options.map(option =>
        <option
          key={option.name + ':' + option.value}
        value={option.value}>
            {option.name}
        </option>)
    }

    return (
        <div className={styles.inputContainer}>            
            <select id={id}
                className={styles.input} 
                value={value.value}
                name={name}
                required={required}
                autoComplete="off"
                onChange={handleChange}
                disabled={disabled} >
                {renderOptions()}
            </select>
            {label && <label htmlFor={id} className={styles.label}>
                {label}
            </label> }
        </div>
    );
}

export default SelectInput;
