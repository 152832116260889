import React, {useCallback} from "react";
import styles from "./TextInput.module.scss";
import classNames from "classnames";

function TextInput(props) {
    const {
        id,
        name = "",
        onBlur,
        value,
        setValue,
        label,
        placeholder,
        type="text",
        required = false,
        disabled = false,
        iconSrc,
        overrideContainerClassName,
    } = props;


    const handleChange = useCallback((event) => {
        if (setValue) {
            setValue(event.target.value);
        }
    }, []);

    const _onBlur = () => {
        if (onBlur) {
            onBlur();
        }
    }

    const renderLabel = () => {
        if (iconSrc && label) {
            return (
                <>
                    <img className={styles.inputPicture} src={iconSrc}/>
                    <label htmlFor={id} className={styles.labelWithPicture}>
                        {label}
                    </label>
                </>
            )
        }

        if (label) {
            return (
                <label htmlFor={id} className={styles.label}>
                    {label}
                </label>
            )
        }

        return;
    }

    return (
        <div className={classNames(styles.inputContainer, overrideContainerClassName)}>
        <input id={id}
                className={styles.input} 
                value={value}
                name={name}
                required={required}
                autoComplete="off"
                onChange={handleChange}
                placeholder={placeholder}
                type={type}
                onBlur={_onBlur}
                disabled={disabled} />
            {renderLabel()}
        </div>
    );
}

export default TextInput;
