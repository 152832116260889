import * as helpers from "./helper";
import moment from "moment/moment";
import BigNumber from "bignumber.js";
import {setShowCreateIdoAccount} from "../Components/Header/statistics";
import toast from "react-hot-toast";
import {errorToast, successToast} from "./toastCSS";
import {web3} from "@project-serum/anchor";
import {SystemProgram, SYSVAR_RENT_PUBKEY} from "@solana/web3.js";

const EMPTY_POOL_HEX = "0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0";

export const IDO_STAGE_UPCOMING = 'Upcoming';
export const IDO_STAGE_HYDRAZINE_CONTRIBUTION = 'Hydrazine Contribution';
export const IDO_STAGE_SETTING_ALLOCATION = 'Setting Allocations';
export const IDO_STAGE_USDC_CONTRIBUTION = 'USDC Contribution';
export const IDO_STAGE_SETTING_FCFS_ALLOCATION = 'Setting FCFS Allocations';
export const IDO_STAGE_FCFS_SALE = 'FCFS Sale';
export const IDO_STAGE_COMPLETED = 'Completed';
export const IDO_STAGE_CLAIM_OPEN = 'Claims Open';
export const IDO_STAGE_REFUNDS_OPEN = 'REFUNDS_OPEN';
export const IDO_STAGE_CLOSED = 'Closed';

export const DEFAULT_IDO_COUNTRIES =  [
  "AX",
  "AL",
  "DZ",
  "AS",
  "AD",
  "AO",
  "AI",
  "AQ",
  "AG",
  "AR",
  "AM",
  "AW",
  "AU",
  "AT",
  "AZ",
  "BS",
  "BH",
  "BD",
  "BB",
  "BY",
  "BE",
  "BZ",
  "BJ",
  "BM",
  "BT",
  "BO",
  "BQ",
  "BA",
  "BW",
  "BV",
  "BR",
  "IO",
  "BN",
  "BG",
  "BF",
  "BI",
  "CV",
  "KH",
  "CM",
  "KY",
  "TD",
  "CL",
  "CX",
  "CC",
  "CO",
  "KM",
  "CK",
  "CR",
  "CI",
  "HR",
  "CW",
  "CY",
  "CZ",
  "DK",
  "DJ",
  "DM",
  "DO",
  "EC",
  "EG",
  "SV",
  "GQ",
  "EE",
  "SZ",
  "ET",
  "FK",
  "FO",
  "FJ",
  "FI",
  "FR",
  "GF",
  "PF",
  "TF",
  "GA",
  "GM",
  "GE",
  "DE",
  "GH",
  "GI",
  "GR",
  "GL",
  "GD",
  "GP",
  "GU",
  "GT",
  "GG",
  "GN",
  "GY",
  "HT",
  "HM",
  "VA",
  "HN",
  "HK",
  "HU",
  "IS",
  "IN",
  "ID",
  "IE",
  "IM",
  "IL",
  "IT",
  "JM",
  "JP",
  "JE",
  "JO",
  "KZ",
  "KE",
  "KI",
  "KR",
  "KW",
  "KG",
  "LA",
  "LV",
  "LS",
  "LR",
  "LI",
  "LT",
  "LU",
  "MO",
  "MK",
  "MG",
  "MW",
  "MY",
  "MV",
  "MT",
  "MH",
  "MQ",
  "MR",
  "MU",
  "YT",
  "MX",
  "FM",
  "MD",
  "MC",
  "MN",
  "ME",
  "MS",
  "MA",
  "MZ",
  "MM",
  "NR",
  "NP",
  "NL",
  "NC",
  "NZ",
  "NI",
  "NE",
  "NG",
  "NU",
  "NF",
  "MP",
  "NO",
  "OM",
  "PK",
  "PW",
  "PS",
  "PA",
  "PG",
  "PY",
  "PE",
  "PH",
  "PN",
  "PL",
  "PT",
  "QA",
  "RE",
  "RO",
  "RU",
  "RW",
  "BL",
  "SH",
  "KN",
  "LC",
  "MF",
  "PM",
  "VC",
  "WS",
  "SM",
  "ST",
  "SA",
  "SN",
  "RS",
  "SC",
  "SL",
  "SG",
  "SX",
  "SK",
  "SI",
  "SB",
  "ZA",
  "GS",
  "ES",
  "LK",
  "SR",
  "SJ",
  "SE",
  "CH",
  "TW",
  "TZ",
  "TH",
  "TL",
  "TG",
  "TK",
  "TO",
  "TT",
  "TN",
  "TR",
  "TM",
  "TC",
  "TV",
  "UG",
  "UA",
  "AE",
  "GB",
  "UM",
  "UY",
  "UZ",
  "VU",
  "VN",
  "VG",
  "WF",
  "EH",
  "ZM",
  "ZW"
]
