export const rgResult = [
  {
    "wallet": "0x0DA56e6EC31366774Ad2D081b1539b7e7353A3DA",
    "amount": 1
  },
  {
    "wallet": "0xE249DA15f8D42B794c6F10267038636ba3663Ac5",
    "amount": 1
  },
  {
    "wallet": "0xBA0E4ddD2948F25749217534eB3a928Bc32fD8Be",
    "amount": 2
  },
  {
    "wallet": "0xA2248712A4A3064617CFAee46E192939f190dc1a",
    "amount": 2
  },
  {
    "wallet": "0x8Daba8DA9A6870917AC1790F2785E618Cb15724A",
    "amount": 3
  },
  {
    "wallet": "0x9f3d05E7c39F40436d4c2D56c1612cE450d00Df9",
    "amount": 1
  },
  {
    "wallet": "0xAE44261682F98b57C6576C6fD83Cc4f3ac551Bf0",
    "amount": 1
  },
  {
    "wallet": "0xA7daf35f448f568086Eb21eF1a3A2948Bf4D4BD4",
    "amount": 2
  },
  {
    "wallet": "0x8Daba8DA9A6870917AC1790F2785E618Cb15724A",
    "amount": 1
  },
  {
    "wallet": "0x7817E0F3877b86371F80746fE2Ef186dF1807e4c",
    "amount": 1
  },
  {
    "wallet": "0x1c419D36514eB84b7479cF45c37B17BA94930502",
    "amount": 2
  },
  {
    "wallet": "0x9f3d05E7c39F40436d4c2D56c1612cE450d00Df9",
    "amount": 1
  },
  {
    "wallet": "0xe975B83c6D3A7Db7DFe99C6E03Ad5a16686999BD",
    "amount": 1
  },
  {
    "wallet": "0x8Daba8DA9A6870917AC1790F2785E618Cb15724A",
    "amount": 1
  },
  {
    "wallet": "0xa7Ce5d6F8A806aB52875d4ae1Db7C955A5FC4BDb",
    "amount": 1
  },
  {
    "wallet": "0xd0Cb1773832Fcd124fB352C46a59a279984BF9cf",
    "amount": 1
  },
  {
    "wallet": "0x6331373C47F1Fd42d030f5bDE4d6c18eC4879D37",
    "amount": 3
  },
  {
    "wallet": "0x8Daba8DA9A6870917AC1790F2785E618Cb15724A",
    "amount": 1
  },
  {
    "wallet": "0x8Daba8DA9A6870917AC1790F2785E618Cb15724A",
    "amount": 2
  },
  {
    "wallet": "0x59129f26b871b72302DF9DCF67e0E9627DC7f376",
    "amount": 1
  },
  {
    "wallet": "0x6331373C47F1Fd42d030f5bDE4d6c18eC4879D37",
    "amount": 2
  },
  {
    "wallet": "0xF4A4800b210d0D40fbBE0C259C794F3000206f1e",
    "amount": 3
  },
  {
    "wallet": "0x7dc25Fb78e39420Ce5Fa5C14F13F0ED770D35dbD",
    "amount": 1
  },
  {
    "wallet": "0x8Daba8DA9A6870917AC1790F2785E618Cb15724A",
    "amount": 2
  },
  {
    "wallet": "0x59129f26b871b72302DF9DCF67e0E9627DC7f376",
    "amount": 1
  },
  {
    "wallet": "0xB2d1Fc01f2c9a45549dddAb5A13CAc750F7f73FA",
    "amount": 2
  },
  {
    "wallet": "0x8Daba8DA9A6870917AC1790F2785E618Cb15724A",
    "amount": 5
  },
  {
    "wallet": "0x5D395a73fb1314155f1bE560fD5DB6f8dBD4001E",
    "amount": 1
  },
  {
    "wallet": "0xAc243BfbfdDEbbC3918828C8a171952b6b79FdB1",
    "amount": 1
  },
  {
    "wallet": "0x59129f26b871b72302DF9DCF67e0E9627DC7f376",
    "amount": 1
  },
  {
    "wallet": "0x7C348516e66fA2a8771844ec8476dbBF0cA2AcBf",
    "amount": 1
  },
  {
    "wallet": "0x4615a75C4Db911aeFC6e10915b2153dbD4ce6d94",
    "amount": 1
  },
  {
    "wallet": "0x59129f26b871b72302DF9DCF67e0E9627DC7f376",
    "amount": 1
  },
  {
    "wallet": "0xe5eF3Fc0eEF668154e777eFdfBB8bf81DEFa5706",
    "amount": 1
  },
  {
    "wallet": "0x7c97A21Eb72C71cB7AF6F6343ed358d0079DAD48",
    "amount": 1
  },
  {
    "wallet": "0x04e4c95275BeE5B02C0d921253Bd61ADF0d4F795",
    "amount": 1
  },
  {
    "wallet": "0x9CaF88f8D02dEd06764Ca2047e4c4d9Fad5c59BD",
    "amount": 1
  },
  {
    "wallet": "0x826F5a52ca81Fd9a65F5153b8999a8448606d1BF",
    "amount": 1
  },
  {
    "wallet": "0x7C348516e66fA2a8771844ec8476dbBF0cA2AcBf",
    "amount": 1
  },
  {
    "wallet": "0x9402a283c4778756670056b52F64E1BA79556916",
    "amount": 1
  },
  {
    "wallet": "0xc687f76F5E6E3a30E4422206B95814A76A3Ff6b6",
    "amount": 1
  },
  {
    "wallet": "0x80f0834D755e7B913e620476B7B1e60d721D354d",
    "amount": 2
  },
  {
    "wallet": "0x5fF40973767AEDA02904Dc537DdB878dE7748a6C",
    "amount": 2
  },
  {
    "wallet": "0x15d87aB4910461a6786A4fbDE0850ed44307052b",
    "amount": 1
  },
  {
    "wallet": "0x372eB409945fe561729162Dd7228717F21bC400F",
    "amount": 1
  }
]
