// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WYjBKFJGYiL4l9LXsB0d {
  display: flex;
  flex-direction: column;
  height: fit-content;
  position: relative;
  max-width: 600px;
}
@media (max-width: 1000px) {
  .WYjBKFJGYiL4l9LXsB0d {
    width: 250px;
  }
}

.MrB1oST46xvpBdHiBE8Q {
  color: #C4C4C4;
  font-size: 14px;
  font-family: Chakra Petch;
  position: absolute;
  text-transform: uppercase;
  top: -16px;
}

.WCluNI05A9yBE8Hr8lj4 {
  color: #C4C4C4;
  font-size: 16px;
  font-weight: bold;
  font-family: Chakra Petch;
  background: rgba(0, 48, 73, 0.75);
  border-bottom: 2px solid #C4C4C4;
  border-radius: 4px;
}
.WCluNI05A9yBE8Hr8lj4::placeholder {
  opacity: 0.5;
}
.WCluNI05A9yBE8Hr8lj4:focus {
  outline: none;
}
.WCluNI05A9yBE8Hr8lj4:focus + .MrB1oST46xvpBdHiBE8Q {
  color: white;
}
.WCluNI05A9yBE8Hr8lj4:disabled {
  color: #C4C4C4;
}`, "",{"version":3,"sources":["webpack://./src/Components/SelectInput/SelectInput.module.scss"],"names":[],"mappings":"AACA;EACC,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;AAAD;AACC;EAND;IAOE,YAAA;EAEA;AACF;;AACA;EACC,cAAA;EACG,eAAA;EACA,yBAAA;EACA,kBAAA;EACA,yBAAA;EACA,UAAA;AAEJ;;AACA;EACC,cAAA;EACG,eAAA;EACH,iBAAA;EACG,yBAAA;EACA,iCAAA;EACA,gCAAA;EACH,kBAAA;AAED;AADC;EACC,YAAA;AAGF;AAAC;EACC,aAAA;AAEF;AAAE;EACC,YAAA;AAEH;AAEC;EACC,cAAA;AAAF","sourcesContent":["\r\n.inputContainer {\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\theight: fit-content;\r\n\tposition: relative;\r\n\tmax-width: 600px;\r\n\t@media (max-width:1000px) {\r\n\t\twidth: 250px;\r\n\t}\r\n}\r\n\r\n.label {\r\n\tcolor: #C4C4C4;\r\n    font-size: 14px;\r\n    font-family: Chakra Petch;\r\n    position: absolute;\r\n    text-transform: uppercase;\r\n    top: -16px;\r\n}\r\n\r\n.input {\r\n\tcolor: #C4C4C4;\r\n    font-size: 16px;\r\n\tfont-weight: bold;\r\n    font-family: Chakra Petch;\r\n    background: rgba(0, 48, 73, 0.75);\r\n    border-bottom: 2px solid #C4C4C4;\r\n\tborder-radius: 4px;\r\n\t&::placeholder {\r\n\t\topacity: 0.5;\r\n\t}\r\n\r\n\t&:focus {\r\n\t\toutline: none;\r\n\r\n\t\t& + .label {\r\n\t\t\tcolor: white;\r\n\t\t}\r\n\t}\r\n\r\n\t&:disabled {\r\n\t\tcolor:#C4C4C4;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": `WYjBKFJGYiL4l9LXsB0d`,
	"label": `MrB1oST46xvpBdHiBE8Q`,
	"input": `WCluNI05A9yBE8Hr8lj4`
};
export default ___CSS_LOADER_EXPORT___;
