// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mf3icH6Nlyd4Wv1XiiQF {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pM5GZiN_pzOa3TyMtgX7 {
  font-family: Chakra Petch;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  color: white;
  text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/screens/Home/Home.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,yBAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,YAAA;EACA,yBAAA;AACJ","sourcesContent":[".landingContainer {\r\n    width: 100%;\r\n    height: 100vh;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.landingTitle {\r\n    font-family: Chakra Petch;\r\n    font-style: normal;\r\n    font-weight: bold;\r\n    font-size: 24px;\r\n    line-height: 24px;\r\n    color: white;\r\n    text-transform: uppercase;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"landingContainer": `mf3icH6Nlyd4Wv1XiiQF`,
	"landingTitle": `pM5GZiN_pzOa3TyMtgX7`
};
export default ___CSS_LOADER_EXPORT___;
