import React, {useEffect, useState} from 'react';
import styles from "./StakerList.module.scss";
import { DataGrid } from '@mui/x-data-grid';
import {calculateBlockchain, hasValue} from "../../helpers/helperFunctions";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import TextInput from "../../Components/TextInput/TextInput";
import Button, {BUTTON_TYPE_SECONDARY} from "../../Components/Button/Button";
import {getApiUrl} from "../../helpers/helper";

function StakerList() {
  const [rows, setRows] = useState();
  const [loading, setLoading] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchWalletText, setSearchWalletText] = useState();

  const api = getApiUrl();


  useEffect( ()=>{
    const fetchSaleData = async () => {
      setLoading(true);
      const offset = (page-1)*pageSize;
      await fetch(api + `/stakers?skip=${offset}&limit=${1000}`, {
        "method": "GET",
        "headers": {"content-type": "application/json", "accept": "*"},
      }).then(res=>res.json())
        .then((response)=>{
          console.log(response?.data?.result)
          setRows(response?.data?.result)
          setTotal(response?.data?.total)

        });

      setLoading(false);
    }

    fetchSaleData();
  },[]);
  const onSearch = async () => {
    setLoading(true);

    await fetch(api + `/search?wallet=${searchWalletText}`)
      .then(res=>res.json())
      .then((response)=>{
        if(response?.status){
          setRows(response?.data);
          setTotal(1)
        }
      })
      .catch(function (error) {
        console.error(error);
      });


    setLoading(false);
  }

  const columns = [
    { field: 'owner', headerName: 'Wallet', width: 500 },
    {
      field: 'staked',
      headerName: 'Staked',
      valueGetter: (value, row) => value / 1000000,
      width: 250 },
    { field: 'totalVaults', headerName: 'totalVaults', width: 250 },
  ];


  const downloadStakersCsv = async () => {
    await fetch(api + `/download`)
      .then(res=>res.json())
      .then((response)=>{
        if(response?.url){
          window.open(response?.url);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <main id="home" key="home" className={styles.mainIdoContainer}>
       <div className={styles.idoAdminContainer}>
         <div className={styles.stakerHeaderContainer}>
             <div className={styles.walletInputContainer}>
               <TextInput label="Wallet Input" value={searchWalletText} setValue={setSearchWalletText}  />
             </div>
            <Button
              text="SEARCH WALLET"
              buttonType={BUTTON_TYPE_SECONDARY}
              disabled={!hasValue(searchWalletText)}
              onClick={onSearch} />
            <div className={styles.flexGrower} />
            <Button text="DOWNLOAD CSV" onClick={downloadStakersCsv} buttonType={BUTTON_TYPE_SECONDARY} />
         </div>
         {loading && <LoadingSpinner local />}
         <div className={styles.tableContainer}>
           { rows &&
             <DataGrid
               rows={rows}
               columns={columns}
               initialState={{
                 pagination: {
                   paginationModel: { page: page, pageSize: pageSize },
                 },
               }}
               pageSizeOptions={[5, 10]}
             />

           }
         </div>

       </div>
    </main>

  );
}

export default StakerList;
