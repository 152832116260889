export function median(data: number[]): number {
  // Only include items in the array that are finite numbers.
  const numbers = data.filter((num) => Number.isFinite(num));
  if (!numbers || numbers.length === 0) return Number.NaN;

  // Sort the array
  const sortedNumbers = numbers.slice().sort((a, b) => a - b);
  const midIndex = Math.floor(sortedNumbers.length / 2);
  return sortedNumbers.length % 2 === 0
    ? // If there are an even number of elements, return the average of the two middle numbers
      (sortedNumbers[midIndex - 1] + sortedNumbers[midIndex]) / 2
    : // If there are an odd number of elements, return the middle number
      sortedNumbers[midIndex];
}
