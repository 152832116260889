import {hasValue, useDebouncedEffect} from "../../helpers/helperFunctions";
import {
    hydrazineDefaultToken,
    NO_TOKENS_RESPONSE,
    solanaDefaultToken,
    starsDefaultToken,
    usdcDefaultToken
} from "../../screens/RaffleEdit/raffleHelper";
import React, {useCallback, useEffect, useState} from "react";
import styles from "./TokenModalSelect.module.scss";
import Bottom from '../../images/svg/bottom.svg'
import TextInput from "../TextInput/TextInput";
import Modal from "../Modal/Modal";


function TokenModalSelect(props) {
    const {
        value,
        setValue,
        options,
        fetchToken,
    } = props;

    const pinnedTokens = [ solanaDefaultToken, starsDefaultToken, usdcDefaultToken, hydrazineDefaultToken ];
    const pinnedTokenMints = [ solanaDefaultToken.value, starsDefaultToken.value, usdcDefaultToken.value, hydrazineDefaultToken.value];

    const [modalOpen, setModalOpen] = useState(false);
    const [searchValue, setSearchValue] = useState();
    const [tokenSearchMessage, setTokenSearchMessage] = useState();


    useDebouncedEffect(async () => {
        if (hasValue(searchValue)) {
            const result = await fetchToken(searchValue);
            if (result === NO_TOKENS_RESPONSE) {
                setTokenSearchMessage('NO TOKENS FOUND')
            }
        }
    }, 700, [searchValue]);

    useEffect(() => {
        if (hasValue(tokenSearchMessage)) setTokenSearchMessage('');
    }, [searchValue]);


    const toggleModalOpen = () => setModalOpen(prev => !prev);

    const setToken = useCallback((token) => {
        if (setValue) {
            setValue(token);
        }
        toggleModalOpen();
    }, [setValue]);

    const renderOptions = useCallback((tokenList, removePinned = false, useSearchFilter = false) => {
        if (!tokenList) {
            return;
        }

        return tokenList
            .filter(opt => {
                if (removePinned === true) {
                    return !pinnedTokenMints.includes(opt.value)
                }

                if (useSearchFilter === true) {
                    return searchValue?.toUpperCase().includes(opt.name.toUpperCase());
                }

                return true;
            })
            .map(option =>

        <div
            onClick={() => setToken(option)}
            className={value?.value === option.value ? styles.tokenOptionActive : styles.tokenOption}
            key={option.value}>
            <img className={styles.tokenIcon} src={option.icon} alt={option.name} />
            {option.key + ' - ' + option.name}
            <div style={{flexGrow: 1}} />
            <span className={styles.tokenOptionBalance}>{hasValue(option.balance) ? '' : ''}</span>
        </div>)
    }, [searchValue])

    return (
    <>
        <div className={styles.modalButtonContainer} onClick={toggleModalOpen}>
            {value.icon && <img className={styles.tokenIcon} src={value.icon}/>}
            {value.key}
            <Bottom/>
        </div>
        {modalOpen &&
          <Modal
            show={modalOpen}
                setShow={toggleModalOpen}>
                <>
                    <div className={styles.searchContainer}>
                        <TextInput value={searchValue} setValue={setSearchValue} placeholder={'Search, Use name or mint'}  />
                    </div>
                    <div className={styles.tokenList}>
                        <div className={styles.tokenInWalletText}>Pinned</div>
                        {renderOptions(pinnedTokens)}
                        {hasValue(searchValue) && <div className={styles.tokenInWalletText}>Result</div>}
                        {hasValue(searchValue) && hasValue(tokenSearchMessage) && <div className={styles.tokenSearchMessage}>{tokenSearchMessage}</div>}
                        {hasValue(searchValue) && renderOptions(options.searchedTokens, true)}
                    </div>
                </>

          </Modal>}
    </>);
}

export default TokenModalSelect;
