import { createSlice } from '@reduxjs/toolkit'

export const raffleData = createSlice({
    name: 'raffleData',
    initialState: {
        allRaffles: [],
    },
    reducers: {
        setRaffleData: (state, data) => {
            const rafflePayload = data.payload;
            state.allRaffles = rafflePayload
        },
    },
})

export const { setRaffleData } = raffleData.actions

export default raffleData.reducer
