// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gyTAwHBkq8PlCZCNc7bJ {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
}

.ehOasBnniFjmVBqrN4LM {
  color: #c6c6c6;
  font-size: 14px;
  font-family: Chakra Petch;
  font-weight: bold;
}

.rgFI1lFBF_xvnOobeE9G {
  width: 50px;
  height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/Components/ImageSubmit/ImageSubmit.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,mBAAA;EACA,gBAAA;AACD;;AAEA;EACC,cAAA;EACA,eAAA;EACA,yBAAA;EACA,iBAAA;AACD;;AAGA;EACC,WAAA;EACA,YAAA;AAAD","sourcesContent":[".imageSubmitContainer {\r\n\tdisplay: flex;\r\n\tflex-direction: row;\r\n\tcolumn-gap: 16px;\r\n}\r\n\r\n.title {\r\n\tcolor: #c6c6c6;\r\n\tfont-size: 14px;\r\n\tfont-family: Chakra Petch;\r\n\tfont-weight: bold;\r\n}\r\n\r\n\r\n.logoImage {\r\n\twidth: 50px;\r\n\theight: 50px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageSubmitContainer": `gyTAwHBkq8PlCZCNc7bJ`,
	"title": `ehOasBnniFjmVBqrN4LM`,
	"logoImage": `rgFI1lFBF_xvnOobeE9G`
};
export default ___CSS_LOADER_EXPORT___;
