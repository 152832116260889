// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CIsl1lgsi2w3QaaBbZ84 {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dQFfz4mRDgR94zhG0ifH {
  width: 80%;
  height: 80%;
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  overflow-y: auto;
}

.FZmpwzvydMKBSDVTBy6U {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  width: 100%;
  height: 50px;
}

.pQt8_6YxoiqVJr8UpECi {
  height: 90%;
  width: 100%;
}

.fQxpSW0o1h48JN0fljD8 {
  max-width: 40%;
}

.T8Bf7wQxjboVMRzYwNZ3 {
  flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/screens/StakerList/StakerList.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,UAAA;EACA,WAAA;EACA,uBAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,WAAA;EACA,YAAA;AACJ;;AACA;EACI,WAAA;EACA,WAAA;AAEJ;;AACA;EACI,cAAA;AAEJ;;AACA;EACI,YAAA;AAEJ","sourcesContent":[".mainIdoContainer {\r\n    width: 100%;\r\n    height: 100vh;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.idoAdminContainer {\r\n    width: 80%;\r\n    height: 80%;\r\n    background-color: white;\r\n    padding: 16px;\r\n    border-radius: 8px;\r\n    overflow-y: auto;\r\n}\r\n\r\n.stakerHeaderContainer {\r\n    display: flex;\r\n    flex-direction: row;\r\n    column-gap: 16px;\r\n    width: 100%;\r\n    height: 50px;\r\n}\r\n.tableContainer {\r\n    height: 90%;\r\n    width: 100%;\r\n}\r\n\r\n.walletInputContainer {\r\n    max-width: 40%;\r\n}\r\n\r\n.flexGrower {\r\n    flex-grow: 1;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainIdoContainer": `CIsl1lgsi2w3QaaBbZ84`,
	"idoAdminContainer": `dQFfz4mRDgR94zhG0ifH`,
	"stakerHeaderContainer": `FZmpwzvydMKBSDVTBy6U`,
	"tableContainer": `pQt8_6YxoiqVJr8UpECi`,
	"walletInputContainer": `fQxpSW0o1h48JN0fljD8`,
	"flexGrower": `T8Bf7wQxjboVMRzYwNZ3`
};
export default ___CSS_LOADER_EXPORT___;
