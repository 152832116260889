import React, { useState, useEffect } from 'react';
import Modal from "../Modal/Modal";
import TextInput from "../TextInput/TextInput";
import styles from "./RaffleManagerModal.module.scss";
import { addRaffleManager, removeRaffleManager, getRaffleManagers } from "../../screens/RaffleEdit/raffleHelper";
import { RaffleErrorHandler } from '../../helpers/errorHelper';
import toast from "react-hot-toast";
import { errorToast, successToast } from "../../helpers/toastCSS";
import { PublicKey } from "@solana/web3.js";

function RaffleManagerModal({ show, setShow, provider, connection, raffleData }) {
  const [managers, setManagers] = useState({ primaryManager: '', additionalManagers: [] });
  const [newManagerAddress, setNewManagerAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (show && raffleData?.poolName) {
      loadManagers();
    }
  }, [show, raffleData]);

  const loadManagers = async () => {
    try {
      const managersData = await getRaffleManagers({
        provider,
        connection,
        raffleName: raffleData.poolName
      });
      setManagers(managersData);
    } catch (err) {
      toast(RaffleErrorHandler.handleManagerErrors(err), errorToast);
    }
  };

  const handleAddManager = async () => {
    if (!newManagerAddress) return;

    try {
      setIsLoading(true);
      const pubkey = new PublicKey(newManagerAddress);
      
      await addRaffleManager({
        provider,
        connection,
        raffleName: raffleData.poolName,
        newManagerAddress: pubkey.toString()
      });
      toast('Manager added successfully!', successToast);
      setManagers(prevManagers => ({
        ...prevManagers,
        additionalManagers: [...prevManagers.additionalManagers, newManagerAddress]
      }));
      setNewManagerAddress('');
    } catch (err) {
      console.error("Failed to add manager:", err);
      toast(RaffleErrorHandler.handleManagerErrors(err), errorToast);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveManager = async (managerAddress) => {
    try {
      setIsLoading(true);
      await removeRaffleManager({
        provider,
        connection,
        raffleName: raffleData.poolName,
        managerAddress
      });

      toast('Manager removed successfully!', successToast);
      setManagers(prevManagers => ({
        ...prevManagers,
        additionalManagers: prevManagers.additionalManagers.filter(
          manager => manager !== managerAddress
        )
      }));
    } catch (err) {
      console.error("Failed to remove manager:", err);
      toast(RaffleErrorHandler.handleManagerErrors(err), errorToast);
    } finally {
      setIsLoading(false);
    }
  };

  const isValidAddress = () => {
    try {
      if (!newManagerAddress) return false;
      new PublicKey(newManagerAddress);
      return true;
    } catch {
      return false;
    }
  };

  return (
    <Modal
      show={show}
      setShow={setShow}
      header="Manage Raffle Editors"
      confirmButtonText="Add Manager"
      confirmButtonDisabled={!isValidAddress() || isLoading}
      onConfirm={handleAddManager}
      exit
    >
      <div className={styles.managerModalContent}>
        <div className={styles.addManagerSection}>
          <TextInput
            value={newManagerAddress}
            setValue={setNewManagerAddress}
            label="New Manager Address"
            placeholder="Enter Solana wallet address"
          />
        </div>

        <div className={styles.managersListSection}>
          <div className={styles.managerSection}>
            <h4>Primary Manager</h4>
            <div className={styles.managerAddress}>
              {managers.primaryManager}
            </div>
          </div>

          <div className={styles.managerSection}>
            <h4>Additional Managers</h4>
            {managers.additionalManagers.map((manager, index) => (
              <div key={index} className={styles.managerRow}>
                <div className={styles.managerAddress}>
                  {manager}
                </div>
                <button
                  onClick={() => handleRemoveManager(manager)}
                  className={styles.removeButton}
                  disabled={isLoading}
                >
                  Remove
                </button>
              </div>
            ))}
            {managers.additionalManagers.length === 0 && (
              <div className={styles.noManagers}>
                No additional managers
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default RaffleManagerModal;