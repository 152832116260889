// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HLR2hATTSKqjt0A_c4zT {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

._o70ZbNPFTJnOPSCwL_0 {
  width: 80%;
  height: 80%;
  background-color: white;
  padding: 8px;
  border-radius: 8px;
}

.Xv1FiC0jVDYsMbyrAH1f {
  overflow-y: auto;
  height: calc(100% - 50px);
}

.EXYD8snz8ByIrTd5fX8y {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.Fu3ngWhzIDyoJlRg7tnM {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/screens/IdoAdministration/IdoAdministration.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,UAAA;EACA,WAAA;EACA,uBAAA;EACA,YAAA;EACA,kBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,yBAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;AACJ","sourcesContent":[".mainIdoContainer {\r\n    width: 100%;\r\n    height: 100vh;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.idoAdminContainer {\r\n    width: 80%;\r\n    height: 80%;\r\n    background-color: white;\r\n    padding: 8px;\r\n    border-radius: 8px;\r\n}\r\n\r\n.idoTableContainer {\r\n    overflow-y: auto;\r\n    height: calc(100% - 50px);\r\n}\r\n\r\n.idoTableFooter {\r\n    width: 100%;\r\n    height: 50px;\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: flex-end;\r\n}\r\n\r\n.editIDOContainer {\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainIdoContainer": `HLR2hATTSKqjt0A_c4zT`,
	"idoAdminContainer": `_o70ZbNPFTJnOPSCwL_0`,
	"idoTableContainer": `Xv1FiC0jVDYsMbyrAH1f`,
	"idoTableFooter": `EXYD8snz8ByIrTd5fX8y`,
	"editIDOContainer": `Fu3ngWhzIDyoJlRg7tnM`
};
export default ___CSS_LOADER_EXPORT___;
