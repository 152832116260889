import './LoadingSpinner.scss';
import React from 'react';
import logo from '../../images/svg/LogoWhite.svg';

export const LOADING_SPINNER_SMALL = 'small'
export const LOADING_SPINNER_MEDIUM = 'medium'
export const LOADING_SPINNER_LARGE = 'large'
function LoadingSpinner(props) {
  const {
    local,
    containerStyles,
    size = null,
  } = props;

  if (local) {
      if (size !== null) {
          return (
              <div className='localRippleContainer' style={containerStyles}>
                  <div className={'lds-ripple-' + size} >
                      <div>
                      </div>
                      <div>
                      </div>
                  </div>
              </div>
          )
      }


      return (
          <div className='localRippleContainer'>
              <div className="lds-ripple" >
                  <div>
                  </div>
                  <div>
        </div>
      </div>
      </div>
    )
  }

  return (
  <div className="loader-wrapper">
      <img className="loaderLogo" src={logo} alt="Logo" />
      <div className="lds-ripple">
        <div>

        </div>
        <div>

        </div>
      </div>
  </div>
  )
}

export default LoadingSpinner;
