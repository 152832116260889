import {useEffect, useRef} from "react";

export const BLOCKCHAIN_ETH = 'ETHEREUM';
export const BLOCKCHAIN_POLYGON = 'POLYGON';
export const BLOCKCHAIN_ARBITRUM = 'ARBITRUM';
export const BLOCKCHAIN_SUPRA = 'SUPRA';
export const BLOCKCHAIN_SUI = 'SUI';
export const BLOCKCHAIN_AVALANCHE = 'AVALANCHE';
export const BLOCKCHAIN_DYMENSION = 'DYMENSION';
export const BLOCKCHAIN_SKALE = 'SKALE';
export const BLOCKCHAIN_BASE = 'BASE';
export const BLOCKCHAIN_SOLANA = 'SOLANA';
export const BLOCKCHAIN_CLASSIFIED = 'CLASSIFIED';
export const BLOCKCHAIN_UNKNOWN = 'UNKNOWN';

export const calculateBlockchain = (ido) => {
    if (ido?.isEthereum === true) {
        return BLOCKCHAIN_ETH;
    }
    if (ido?.isPolygon === true) {
        return BLOCKCHAIN_POLYGON;
    }

    if (ido?.isArbitrum === true) {
        return BLOCKCHAIN_ARBITRUM;
    }

    if (ido?.isSupra) {
        return BLOCKCHAIN_SUPRA;
    }

    if (ido?.isSol === true) {
        return BLOCKCHAIN_SOLANA;
    }

    if (ido?.isSUI === true) {
        return BLOCKCHAIN_SUI;
    }

    if (ido?.isAvalanche === true) {
        return BLOCKCHAIN_AVALANCHE;
    }

    if (ido?.isClassified === true) {
        return 'CLASSIFIED';
    }

    return 'UNKNOWN';
};

export const hasValue = (s) => {
    return s !== null && typeof s !== 'undefined' && s !== '' && s.length !== 0;
}
export const useDebouncedEffect = (effect, delay, deps) => {
    const callback = useRef();
    callback.current = effect;

    useEffect(() => {
        const handler = setTimeout(() => {
            if (callback.current) {
                callback.current();
            }
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [...(deps || []), delay]);
};

