import React, {useState} from "react";
import styles from './EditProjectInterestModal.module.scss';
import {PROD_API_URL} from "../../constants/constants";
import toast from "react-hot-toast";
import {errorToast, successToast} from "../../helpers/toastCSS";
import TextInput from "../../Components/TextInput/TextInput";
import Button, {BUTTON_TYPE_SECONDARY} from "../../Components/Button/Button";
import {hasValue} from "../../helpers/helperFunctions";
import {Checkbox, FormControlLabel} from "@mui/material";
import {useSelector} from "react-redux";

function EditProjectInterestModal(props) {
    const {
        data,
        setData,
        onComplete,
    } = props;

  const [interested, setInterested] = useState(data.interested === 'YES')
  const [amount, setAmount] = useState(data.amount);
  const [deleteWarningTextVisible, setDeleteWarningTextVisible] = useState(false);

  const solanaWallet = data.id;

  const closeModal = () => {
    setData(null);
  }

  const saveCadetData = async () => {
    const resp = await fetch(PROD_API_URL + '/cadetProjectInterest/createOrEdit', {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "accept": "*",
        'Cookie': 'sails.sid=s%3Avh-tedPOHnJh8E0RhjeC820oxrGU6DwJ.5AwSIniluWQcyz%2B6f7UJ8fDteBEX08UIFEXBltW6tfc'
      },
      body: JSON.stringify({
        solanaWallet: solanaWallet,
        projectName: data.projectName,
        interest: interested,
        amount,
      }),
    });

    if (resp.ok) {
      toast('Project Interest Updated!', successToast)
      setTimeout(() => onComplete(), 1000)
    } else {
      toast('Saving Project Interest failed', errorToast)
    }
    closeModal();
  }

  const deleteCadetInterest = async () => {
    const resp = await fetch(PROD_API_URL + '/cadetProjectInterest/delete', {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "accept": "*",
        'Cookie': 'sails.sid=s%3Avh-tedPOHnJh8E0RhjeC820oxrGU6DwJ.5AwSIniluWQcyz%2B6f7UJ8fDteBEX08UIFEXBltW6tfc'
      },
      body: JSON.stringify({
        solanaWallet: solanaWallet,
        projectName: data.projectName,
      }),
    });

    if (resp.ok) {
      toast('Project Interest Deleted!', successToast)
      setTimeout(() => onComplete(), 1000)
    } else {
      toast('Deleting Project Interest failed', errorToast)
    }
    closeModal();
  }

  const handleDelete = () => {
    if (deleteWarningTextVisible === false) {
      setDeleteWarningTextVisible(true);
    } else {
      deleteCadetInterest()
    }
  }


  return (
      <div className={styles.editCadetOverlay}>
        <div className={styles.editCadetContainer}>
          <h4>SOL WALLET: {solanaWallet}</h4>

          <div className={styles.checkboxContainer}>
            <FormControlLabel
              control={<Checkbox checked={interested}
                                 onChange={event => setInterested(event.target.checked)}/>}
              label="Interested"
              labelPlacement="Top"
            />
          </div>
          <TextInput value={amount} setValue={setAmount} label="Amount" disabled={interested === false}/>
          {deleteWarningTextVisible && <span className={styles.warningText}>ARE YOU SURE YOU WANT TO DELETE THIS INTEREST?</span>}
          <div className={styles.editCadetFooter}>
            <Button text="CANCEL" onClick={closeModal} buttonType={BUTTON_TYPE_SECONDARY}/>
            <div style={{flexGrow: 1}} />
            <Button
              className={styles.deleteButton}
              buttonType={BUTTON_TYPE_SECONDARY}
              text="DELETE"
              onClick={handleDelete}/>
            <Button text="SUBMIT CHANGES" onClick={saveCadetData} buttonType={BUTTON_TYPE_SECONDARY}/>
          </div>
        </div>
      </div>
  );
}

export default EditProjectInterestModal;
