// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uUu4n7eFFup1AbPi78jK {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.jVTF63CFERIksOPI50lr {
  width: 80%;
  height: 85%;
  background-color: white;
  padding: 8px;
  border-radius: 8px;
}

.dzVTyHmavBvau6H6yGRG {
  overflow-y: auto;
  max-height: 80%;
  height: fit-content;
}

.a0_69uWvO985TcCyBJ8F {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.v25xnVSsgeyk5GUgCY1Y {
  width: 30%;
}

.RrmSFBT_mX13uE1_CgIm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: black;
  font-size: 14px;
  font-family: "Chakra Petch";
  width: 70%;
  padding-right: 16px;
  padding-left: 16px;
  font-weight: 600;
}

.LzcPzL2MDsQg5mme4fIi {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.D8Gsi0fG0uH5VKFcLQkt {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/screens/UserAdministration/UserAdministration.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,UAAA;EACA,WAAA;EACA,uBAAA;EACA,YAAA;EACA,kBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,eAAA;EACA,mBAAA;AACJ;;AAEA;EACI,WAAA;EACA,aAAA;EACA,mBAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;EACA,2BAAA;EACA,UAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;AACJ","sourcesContent":[".mainIdoContainer {\r\n    width: 100%;\r\n    height: 100vh;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.userAdminContainer {\r\n    width: 80%;\r\n    height: 85%;\r\n    background-color: white;\r\n    padding: 8px;\r\n    border-radius: 8px;\r\n}\r\n\r\n.idoTableContainer {\r\n    overflow-y: auto;\r\n    max-height: 80%;\r\n    height: fit-content;\r\n}\r\n\r\n.cadetWalletToolbar {\r\n    width: 100%;\r\n    display: flex;\r\n    flex-direction: row;\r\n}\r\n\r\n.filterContainer {\r\n    width: 30%;\r\n}\r\n\r\n.walletStatistics {\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    color: black;\r\n    font-size: 14px;\r\n    font-family: \"Chakra Petch\";\r\n    width: 70%;\r\n    padding-right: 16px;\r\n    padding-left: 16px;\r\n    font-weight: 600;\r\n}\r\n\r\n.idoTableFooter {\r\n    width: 100%;\r\n    height: 50px;\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: flex-end;\r\n}\r\n\r\n.editIDOContainer {\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainIdoContainer": `uUu4n7eFFup1AbPi78jK`,
	"userAdminContainer": `jVTF63CFERIksOPI50lr`,
	"idoTableContainer": `dzVTyHmavBvau6H6yGRG`,
	"cadetWalletToolbar": `a0_69uWvO985TcCyBJ8F`,
	"filterContainer": `v25xnVSsgeyk5GUgCY1Y`,
	"walletStatistics": `RrmSFBT_mX13uE1_CgIm`,
	"idoTableFooter": `LzcPzL2MDsQg5mme4fIi`,
	"editIDOContainer": `D8Gsi0fG0uH5VKFcLQkt`
};
export default ___CSS_LOADER_EXPORT___;
