// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s1carw74n5grGkaFOv8Y {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.u0KAaEPnj8fjB295_adb {
  font-family: "Chakra Petch";
  font-size: 20px;
  font-weight: 500;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  min-width: 30vw;
}

.thkuzVSbDlAn1e9LHEzw {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/screens/UserAdministration/EditCadetModal.module.scss"],"names":[],"mappings":"AAAA;EACC,oCAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACD;;AAEA;EACC,2BAAA;EACA,eAAA;EACA,gBAAA;EACA,uBAAA;EACA,kBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;EACA,eAAA;AACD;;AAEA;EACC,WAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AACD","sourcesContent":[".editCadetOverlay {\r\n\tbackground-color: rgba(0, 0, 0, 0.6);\r\n\tposition: absolute;\r\n\twidth: 100%;\r\n\theight: 100%;\r\n\ttop: 0;\r\n\tleft: 0;\r\n\tdisplay: flex;\r\n\tjustify-content: center;\r\n\talign-items: center;\r\n}\r\n\r\n.editCadetContainer {\r\n\tfont-family: \"Chakra Petch\";\r\n\tfont-size: 20px;\r\n\tfont-weight: 500;\r\n\tbackground-color: white;\r\n\tborder-radius: 8px;\r\n\tpadding: 20px;\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\trow-gap: 16px;\r\n\tmin-width: 30vw;\r\n}\r\n\r\n.editCadetFooter {\r\n\twidth: 100%;\r\n\tdisplay: flex;\r\n\tflex-direction: row;\r\n\tjustify-content: space-between;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editCadetOverlay": `s1carw74n5grGkaFOv8Y`,
	"editCadetContainer": `u0KAaEPnj8fjB295_adb`,
	"editCadetFooter": `thkuzVSbDlAn1e9LHEzw`
};
export default ___CSS_LOADER_EXPORT___;
