// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TsVgashYWKMPGR0PNaM4 {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 14px;
  margin-bottom: 26px;
  width: 100%;
}

.Z31_QivFogR5tpgj0LhX {
  color: grey;
  font-size: 14px;
  font-family: Chakra Petch;
  position: absolute;
  text-transform: uppercase;
  top: -16px;
}

.JRckOhiW2TXjdEqhhD7G {
  color: grey;
  font-size: 16px;
  font-weight: bold;
  font-family: Chakra Petch;
  background: transparent;
  border: initial;
  border-bottom: 2px solid #C4C4C4;
}
.JRckOhiW2TXjdEqhhD7G:focus {
  outline: none;
}
.JRckOhiW2TXjdEqhhD7G:focus + .Z31_QivFogR5tpgj0LhX {
  color: #48CAE4;
}
.JRckOhiW2TXjdEqhhD7G:disabled {
  color: #C4C4C4;
}`, "",{"version":3,"sources":["webpack://./src/Components/TextAreaInput/TextAreaInput.module.scss"],"names":[],"mappings":"AACA;EACC,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,WAAA;AAAD;;AAGA;EACC,WAAA;EACG,eAAA;EACA,yBAAA;EACA,kBAAA;EACA,yBAAA;EACA,UAAA;AAAJ;;AAGA;EACC,WAAA;EACG,eAAA;EACH,iBAAA;EACG,yBAAA;EACA,uBAAA;EACA,eAAA;EACA,gCAAA;AAAJ;AAEC;EACC,aAAA;AAAF;AAEE;EACC,cAAA;AAAH;AAIC;EACC,cAAA;AAFF","sourcesContent":["\r\n.inputContainer {\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\tposition: relative;\r\n\tmargin-top: 14px;\r\n\tmargin-bottom: 26px;\r\n\twidth: 100%;\r\n}\r\n\r\n.label {\r\n\tcolor: grey;\r\n    font-size: 14px;\r\n    font-family: Chakra Petch;\r\n    position: absolute;\r\n    text-transform: uppercase;\r\n    top: -16px;\r\n}\r\n\r\n.input {\r\n\tcolor: grey;\r\n    font-size: 16px;\r\n\tfont-weight: bold;\r\n    font-family: Chakra Petch;\r\n    background: transparent;\r\n    border: initial;\r\n    border-bottom: 2px solid #C4C4C4;\r\n\r\n\t&:focus {\r\n\t\toutline: none;\r\n\r\n\t\t& + .label {\r\n\t\t\tcolor: #48CAE4;\r\n\t\t}\r\n\t}\r\n\r\n\t&:disabled {\r\n\t\tcolor:#C4C4C4; \r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": `TsVgashYWKMPGR0PNaM4`,
	"label": `Z31_QivFogR5tpgj0LhX`,
	"input": `JRckOhiW2TXjdEqhhD7G`
};
export default ___CSS_LOADER_EXPORT___;
