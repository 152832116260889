
//All possible errors from the smart contract
export const RaffleErrors = {
    RaffleFull: {
      code: 6000,
      name: 'RaffleFull',
      msg: 'The raffle is already full.'
    },
    NoEntries: {
      code: 6001,
      name: 'NoEntries',
      msg: 'No entries in the raffle.'
    },
    NoRandomnessRequested: {
      code: 6002,
      name: 'NoRandomnessRequested',
      msg: 'Randomness has not been requested.'
    },
    InvalidRandomness: {
      code: 6003,
      name: 'InvalidRandomness',
      msg: 'Invalid randomness received.'
    },
    RaffleNotStarted: {
      code: 6004,
      name: 'RaffleNotStarted',
      msg: 'The raffle has not started yet.'
    },
    RaffleEnded: {
      code: 6005,
      name: 'RaffleEnded',
      msg: 'The raffle has ended.'
    },
    ExceedsMaxEntriesPerParticipant: {
      code: 6006,
      name: 'ExceedsMaxEntriesPerParticipant',
      msg: 'Participant has exceeded the maximum number of allowed entries.'
    },
    UnauthorizedManager: {
      code: 6007,
      name: 'UnauthorizedManager',
      msg: 'Not authorized to manage this raffle'
    },
    ManagerAlreadyExists: {
      code: 6008,
      name: 'ManagerAlreadyExists',
      msg: 'Manager already exists'
    },
    ManagerDoesNotExist: {
      code: 6009,
      name: 'ManagerDoesNotExist',
      msg: "Manager doesn't exist"
    },
    MaxManagersReached: {
      code: 6010,
      name: 'MaxManagersReached',
      msg: 'Maximum number of managers reached'
    }
  };
  

  export const handleRaffleError = (error) => {

    //Check if it's an Anchor error
    if (error?.error?.errorCode) {
      const code = error.error.errorCode.number;
      const errorMatch = Object.values(RaffleErrors).find(e => e.code === code);
      if (errorMatch) {
        return errorMatch;
      }
    }
  
    // Fallback if not found in error code check log and match for error number
    if (error?.logs) {
      for (const log of error.logs) {
        const match = log.match(/Error Number: (\d+)/);
        if (match) {
          const code = parseInt(match[1]);
          const errorMatch = Object.values(RaffleErrors).find(e => e.code === code);
          if (errorMatch) {
            return errorMatch;
          }
        }
      }
    }
  
    return null;
  };
  
  export const formatRaffleError = (error) => {
    const raffleError = handleRaffleError(error);
    
    if (raffleError) {
      return raffleError.msg;
    }
  
    
    if (error?.message?.includes('insufficient funds')) {
      return 'Insufficient funds to complete the transaction.';
    }
    
    if (error?.message?.includes('blockhash not found')) {
      return 'Transaction timeout. Please try again.';
    }
  
    console.error('Unhandled error:', error);
    return 'An unexpected error occurred. Please try again.';
  };
  
  export const isRaffleError = (error, errorName) => {
    const raffleError = handleRaffleError(error);
    return raffleError?.name === errorName;
  };
  
  export const RaffleErrorHandler = {
    handleManagerErrors: (error) => {
      if (isRaffleError(error, 'ManagerAlreadyExists')) {
        return 'This wallet is already a manager';
      }
      
      if (isRaffleError(error, 'MaxManagersReached')) {
        return 'Maximum number of managers reached';
      }
      
      if (isRaffleError(error, 'UnauthorizedManager')) {
        return 'Wallet is not authorized manager';
      }
      
      if (isRaffleError(error, 'ManagerDoesNotExist')) {
        return 'Manager not found';
      }
  
      return formatRaffleError(error);
    },
  
    handleRaffleEntryErrors: (error) => {
      if (isRaffleError(error, 'RaffleFull')) {
        return 'This raffle is full';
      }
      
      if (isRaffleError(error, 'RaffleNotStarted')) {
        return 'This raffle has not started yet';
      }
      
      if (isRaffleError(error, 'RaffleEnded')) {
        return 'This raffle has ended';
      }
      
      if (isRaffleError(error, 'ExceedsMaxEntriesPerParticipant')) {
        return 'You have reached the maximum number of entries allowed';
      }
  
      return formatRaffleError(error);
    },
  
    handleWinnerPickErrors: (error) => {
      if (isRaffleError(error, 'NoEntries')) {
        return 'No entries in the raffle';
      }
      
      if (isRaffleError(error, 'RaffleNotStarted')) {
        return 'Raffle has not started yet';
      }
      
      if (isRaffleError(error, 'RaffleEnded')) {
        return 'Raffle has already ended';
      }
  
      return formatRaffleError(error);
    }
  };