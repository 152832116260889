import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import styles from "./ProjectInterest.module.scss";
import {PROD_API_URL} from "../../constants/constants";
import { hasValue} from "../../helpers/helperFunctions";
import moment from "moment/moment";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import {DataGrid} from "@mui/x-data-grid";
import SelectInput from "../../Components/SelectInput/SelectInput";
import TextInput from "../../Components/TextInput/TextInput";
import EditProjectInterestModal from "./EditProjectInterestModal";
import {rgResult} from "./userData";
import {Checkbox, FormControlLabel} from "@mui/material";

const columns = [
  { field: 'id', headerName: 'Wallet', flex: 1.5 },
  { field: 'interested', headerName: 'Interested', flex: 0.5},
  { field: 'amount', headerName: 'Amount', flex: 0.5 },
  { field: 'amountPurchased', headerName: 'Purchased Amount', flex: 0.5 },
  { field: 'projectName', headerName: 'Project Name', flex: 0.5 },
  { field: 'createdAt', headerName: 'Created At (UTC)', flex: 0.5  },
];

export const NEW_IDO_ID = 'newIDO'
function ProjectInterest() {
  const [rows, setRows] = useState();
  const [userData, setUserData] = useState([]);
  const [projectStatistics, setProjectStatistics] = useState();
  const [projectOptions, setProjectOptions] = useState();
  const [selectedProject, setSelectedProject] = useState();
  const [solWallet, setSolWallet] = useState();
  const [editAdminModalData, setEditModalData] = useState();

  const [filterByInterested, setFilterByInterested] = useState(false);
  const [filterByNotInterested, setFilterByNotInterested] = useState(false);
  const [filterByParticipated, setFilterByParticipated] = useState(false);

  const tableRef = useRef();

  useEffect( ()=>{
    fetchSaleData();
  },[]);

  useEffect(() => {
    if (selectedProject && userData.length > 0) {
      const mappedRows = userData.filter(data => {
        const projectData = data.projects[selectedProject?.value];
        if (!hasValue(projectData) || !hasValue(projectData.name)) return false;

        if (hasValue(solWallet) && !data.solanaWallet.includes(solWallet)) return false;

        if (filterByInterested === true && projectData.interested !== true) return false;

        if (filterByParticipated === true && (!hasValue(projectData.amountPurchased) || Number(projectData?.amountPurchased) === 0)) return false;

        if (filterByNotInterested === true && projectData.interested === true) return false;

        return true;
      }).map(data => {
        const currentProj = data.projects[selectedProject?.value];
        let interest;
        if (currentProj.hasOwnProperty('interested')) {
          interest = currentProj.interested === true ? 'YES' : 'NO';
        } else {
          interest = 'NO VOTE'
        }

        return {
          id: data.solanaWallet,
          interested: interest,
          amount: currentProj.amount,
          amountPurchased: hasValue(currentProj.amountPurchased) ? currentProj.amountPurchased : 'NOT PARTICIPATED',
          projectName: currentProj.name,
          createdAt: moment.unix(data.createdAt / 1000).format('YYYY-MM-DD , h:mm A'),
        }
      })
      setRows(mappedRows);
    }
  }, [selectedProject, userData, filterByInterested, filterByParticipated, filterByNotInterested]);

  const fetchSaleData = async () => {
    fetch(PROD_API_URL+"/cadetProjectInterest/", {
      "method": "GET",
      "headers": {"content-type": "application/json", "accept": "*"},
    }).then(res=>res.json())
      .then((result)=>{
        parseResult(result);

        setTimeout(() => tableRef.current.autosizeColumns({
          includeOutliers: true,
          includeHeaders: true,
        }), 100)
      });
  }

  const saveUserData = async () => {
    for (let i = 0; rgResult.length > i; i++) {
      const data = rgResult[i];
      await saveResult(data);
    }
  }

  const saveResult = async (data) => {

    await fetch('http://localhost:1337' + '/cadetProjectInterest/amountPurchased', {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "accept": "*",
        'Cookie': 'sails.sid=s%3Avh-tedPOHnJh8E0RhjeC820oxrGU6DwJ.5AwSIniluWQcyz%2B6f7UJ8fDteBEX08UIFEXBltW6tfc'
      },
      body: JSON.stringify({
        arbitrumWallet: data.wallet,
        projectName: 'Revolving Games',
        amountPurchased: data.amount,
      }),
    });
  }

  const handleFilter = (value) => {
    if (rows) {
      const filteredCadetWallet = rows?.filter(row => row.id.includes(value));
      setRows(filteredCadetWallet);
    }
    setSolWallet(value);
  }

  const parseResult = (result) => {
    const projects = [];
    const statistics = {};
    const wallets = [];

    let total = 0;

    result.data.forEach(item => {
      console.log(item)
      const curr = item.projects['RevolvingGames']
      if (curr) {
        if (!curr.hasOwnProperty('interested')) {
          console.log(curr)
        }
        if (curr.hasOwnProperty('amountPurchased')) {
          total = total + Number(curr.amountPurchased)
        }
      }

    })
    console.log(total)

    for (let i = 0; result.data.length > i; i++) {
      const user = result.data[i];
      const userProjects = Object.keys(user.projects)
      userProjects.forEach(proj => {
        const userProjectData = user.projects[proj];
        if (!projects.find(p => p.value === proj)) {
          projects.push({
            key: proj,
            name: userProjectData.name,
            label: userProjectData.name,
            value: proj,
          });
        }

        if (hasValue(statistics[proj])) {
          if (hasValue(userProjectData.amountPurchased) && Number(userProjectData.amountPurchased) > 0) {
            statistics[proj].participated = statistics[proj].participated + 1;
            statistics[proj].totalPurchasedAmount = Number(statistics[proj].totalPurchasedAmount) +  Number(userProjectData.amountPurchased);
          } else {
            console.log(userProjectData)
          }
          if (userProjectData.interested === true) {
            statistics[proj].total = statistics[proj].total + 1;
            statistics[proj].yes = statistics[proj].yes + 1;
            statistics[proj].amount = Number(statistics[proj].amount) +  Number(userProjectData.amount);
          } else {
            if (userProjectData.hasOwnProperty('interested')) {
              statistics[proj].no = statistics[proj].no + 1;
              statistics[proj].total = statistics[proj].total + 1;
            } else {
              statistics[proj].notVoted = statistics[proj].notVoted + 1;
            }
          }
        } else {
          statistics[proj] = {
            total: 1,
            yes: userProjectData.interested === true ? 1 : 0,
            no: userProjectData.interested === true || !userProjectData.hasOwnProperty('interested') ? 0 : 1,
            notVoted: !userProjectData.hasOwnProperty('interested'),
            participated: hasValue(userProjectData.amountPurchased) && Number(userProjectData.amountPurchased) > 0 ? 1 : 0,
            totalPurchasedAmount: hasValue(userProjectData.amountPurchased) ? Number(userProjectData.amountPurchased) : 0,
            amount: Number(userProjectData.amount),
          };
        }

      })
    }

    setProjectStatistics(statistics);
    setProjectOptions(projects);
    setSelectedProject(projects[0]);
    setUserData(result.data)
  }

  const currentProjStatistic = hasValue(projectStatistics) ? projectStatistics[selectedProject?.value] : {}

  return (
    <main id="home" key="home" className={styles.mainIdoContainer}>
      <div className={styles.idoAdminContainer}>
        <div className={styles.idoTableContainer}>
          {!rows && <div>
            <h3 >Project Interests</h3>
            <LoadingSpinner local/>
          </div>}
          {rows && <SelectInput options={projectOptions} value={selectedProject} setValue={setSelectedProject} label="Pick Project"/>}
          {rows && <div className={styles.filterRow}>
            <div className={styles.filterContainer}>
              <TextInput label="Filter SOL Wallet" setValue={handleFilter} value={solWallet}/>
              <div className={styles.filterCheckboxRow}>
                <div className={styles.checkboxContainer}>
                  <FormControlLabel
                    control={<Checkbox checked={filterByInterested}
                                       onChange={event => setFilterByInterested(event.target.checked)}/>}
                    label="Only Interested"
                    labelPlacement="Top"
                  />
                </div>
                <div className={styles.checkboxContainer}>
                  <FormControlLabel
                    control={<Checkbox checked={filterByParticipated}
                                       onChange={event => setFilterByParticipated(event.target.checked)}/>}
                    label="Only Participated"
                    labelPlacement="Top"
                  />
                </div>
                <div className={styles.checkboxContainer}>
                  <FormControlLabel
                    control={<Checkbox checked={filterByNotInterested}
                                       onChange={event => setFilterByNotInterested(event.target.checked)}/>}
                    label="Only None-interests"
                    labelPlacement="Top"
                  />
                </div>
                <button onClick={saveUserData}>test</button>
              </div>
            </div>
            <div className={styles.statisticsRow}>
              <div className={styles.statisticsBox}>
                <span className={styles.statisticsLabel}>Total Numb: Votes:</span>
                <span className={styles.statisticsValue}>{currentProjStatistic?.total}</span>
              </div>
              <div className={styles.statisticsBox}>
                <span className={styles.statisticsLabel}>Yes Votes:</span>
                <span className={styles.statisticsValue}>{currentProjStatistic?.yes}</span>
              </div>
              <div className={styles.statisticsBox}>
                <span className={styles.statisticsLabel}>No Votes:</span>
                <span className={styles.statisticsValue}>{currentProjStatistic?.no}</span>
              </div>
              <div className={styles.statisticsBox}>
                <span className={styles.statisticsLabel}>Total Amount Voted:</span>
                <span className={styles.statisticsValue}>{currentProjStatistic?.amount}</span>
              </div>
              <div className={styles.statisticsBox}>
                <span className={styles.statisticsLabel}>Total Amount Purchased:</span>
                <span className={styles.statisticsValue}>{currentProjStatistic?.totalPurchasedAmount}</span>
              </div>
              <div className={styles.statisticsBox}>
                <span className={styles.statisticsLabel}>Participants:</span>
                <span className={styles.statisticsValue}>{currentProjStatistic?.participated}</span>
              </div>
            </div>
          </div>}
          {rows &&
            <div className={styles.tableContainer}>
              <DataGrid
                apiRef={tableRef}
                rows={rows}
                columns={columns}
                onCellDoubleClick={(params) => setEditModalData(params.row)}
                initialState={{
                  pagination: {
                    paginationModel: {page: 1, pageSize: 10},
                  },
                }}
                pageSizeOptions={[5, 10, 50]}
              />
            </div>
          }
        </div>
      </div>
      {editAdminModalData && <EditProjectInterestModal data={editAdminModalData} setData={setEditModalData} onComplete={fetchSaleData} /> }
    </main>

  );
}

export default ProjectInterest;
