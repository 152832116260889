import React from 'react';
import styles from "./Home.module.scss";



function Home() {


  return (
    <main id="home" key="home" className={styles.landingContainer}>
       <h1 className={styles.landingTitle}>WELCOME TO STARLAUNCH ADMIN PANEL</h1>
    </main>

  );
}

export default Home;
