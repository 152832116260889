import { configureStore } from '@reduxjs/toolkit'
import statisticsReducer from './Components/Header/statistics'
import idoData from './Components/Header/idoData'
import userData from "./Components/Header/userData";
import raffleData from "./Components/Header/raffleData";

export default configureStore({
  reducer: {
      idoData: idoData,
      raffleData: raffleData,
      statistics: statisticsReducer,
      userData: userData,
  },
})
