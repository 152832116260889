import { createSlice } from '@reduxjs/toolkit'

export const userData = createSlice({
    name: 'userData',
    initialState: {
        adminUser: null,
    },
    reducers: {
        setAdminUser: (state, data) => {
            state.adminUser = data.payload;
        }
    },
})

export const {
    setAdminUser,
    } = userData.actions

export default userData.reducer
