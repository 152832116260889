import React, {Children, Fragment, useEffect, useState} from "react";
import { Modal as _Modal, Form } from "react-bootstrap";
import Button, { BUTTON_TYPE_PRIMARY, BUTTON_TYPE_PRIMARY_BRIGHT, BUTTON_TYPE_SECONDARY } from "../Button/Button";
import styles from "./Modal.module.scss";
import {hasValue} from "../../helpers/helperFunctions";

export const MODAL_TOOLTIP_ID = 'modalTooltip';

function Modal(props) {
    const {
        children,
        header,
        title,
        text,
        confirmButtonText,
        confirmButtonIcon,
        confirmButtonDisabled,
        onConfirm,
        setShow,
        show,
        exit,
    } = props;

    const [error2, setError2] = useState(false);
    const [errorText2, setErrorText2] = useState("");
    const [error, setError] = useState(false);
    const [boxAmount, setBoxAmount] = useState(0);
    const [boxId, setBoxId] = useState(null);

    const [lockState, setLockState] = useState(null);
    const [errorText, setErrorText] = useState("");

    const handleClose = () => setShow(false);
    function updateBoxAmount(e) {
        if (e.target.value > 0) {
            setError2(0);
        }
        setBoxAmount(e.target.value);
    }

    function updateLockState(e) {
        if (e.target.value === "1")  {
            setError(true);
            setErrorText("Your STARS in the box will decay at ~0.767% per day");
            setError2(false);
        } else if (e.target.value === "2") {
            setError(true);
            setErrorText("Your STARS in the box will be locked for 90 days. Unlocking early shall involve a penalty");
            setError2(false);
        }
        setLockState(e.target.value);
    }


    if(children) {
        return (
          <_Modal
            show={show}
            onHide={() => {setShow(false); setError(false); setError2(false);}}
            className={styles.modal}
            centered>
              {header && <_Modal.Header className={styles.modalHeader}>
                  {header}
              </_Modal.Header>}
              <_Modal.Body className={styles.modalBody}>
                  {children}
              </_Modal.Body>
              <_Modal.Footer className={styles.modalFooter}>
                  {
                    exit
                    &&
                    <Button className={styles.exitButton}
                            text="Exit"
                            isProgressButton
                            muteHover
                            buttonType={BUTTON_TYPE_SECONDARY}
                            onClick={handleClose} />
                  }

                  { hasValue(confirmButtonText) &&
                    <Button
                      className={styles.exitButton}
                      text={confirmButtonText}
                      disabled={confirmButtonDisabled}
                      buttonType={BUTTON_TYPE_PRIMARY_BRIGHT}
                      isProgressButton
                      Icon={confirmButtonIcon}
                      onClick={onConfirm} />
                  }
              </_Modal.Footer>
          </_Modal>
        );
    } else {
        return (
          <_Modal
            show={show}
            onHide={() => {setShow(false); setError(false); setError2(false);}}
            className={styles.modal}
            centered>
              <_Modal.Body className={styles.modalBody}>
                  <h3>{title}</h3>
                  <span>{text}</span>
              </_Modal.Body>
          </_Modal>
        );
    }
}

export default Modal;


