import React from "react";
import {createRoot} from "react-dom/client";
import "./index.css";
import App from "./App.jsx";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter as Router
} from "react-router-dom";
import { Buffer } from "buffer";
import "bootstrap/dist/css/bootstrap.min.css";
window.Buffer = Buffer;


const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Router>
    <App />
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
