import React, {useEffect, useState} from 'react';
import styles from "./IdoCreateOrEdit.module.scss";
import {PROD_API_URL} from "../../constants/constants";
import {setIdoData} from "../../Components/Header/idoData";
import { useSelector, useDispatch } from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import TextInput from "../../Components/TextInput/TextInput";
import TextAreaInput from "../../Components/TextAreaInput/TextAreaInput";
import {Checkbox, FormControlLabel, MenuItem, TextField} from "@mui/material";
import {Editor, EditorState} from 'draft-js';
import Button, {BUTTON_TYPE_NO_STYLE, BUTTON_TYPE_PRIMARY, BUTTON_TYPE_SECONDARY} from "../../Components/Button/Button";
import {PATH_IDO_ADMINISTRATION} from "../../App";
import DraftEditor from "../../Components/DraftEditor/DraftEditor";
import {NEW_IDO_ID} from "../IdoAdministration/IdoAdministration";
import {hasValue} from "../../helpers/helperFunctions";
import {DEFAULT_IDO_COUNTRIES} from "../../helpers/idoHelper";
import ImageSubmit from "../../Components/ImageSubmit/ImageSubmit";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment/moment";
import toast from "react-hot-toast";
import {errorToast, successToast} from "../../helpers/toastCSS";

const idoStatuses = [
  {
    value: 'Ongoing',
    label: 'Ongoing',
  },
  {
    value: 'Upcoming',
    label: 'Upcoming',
  },
  {
    value: 'Completed',
    label: 'Completed',
  },
  {
    value: 'Refund',
    label: 'Refund',
  }
]

const idoTypes = [
  {
    value: 'PUBLIC',
    label: 'Public Sale',
  },
  {
    value: 'PRIVATE',
    label: 'Private Sale',
  },
  {
    value: 'NODE',
    label: 'NODE SALE',
  },
  {
    value: 'NFT',
    label: 'NFT SALE',
  },
  {
    value: 'LAUNCH_VOTE',
    label: 'Project Launch Vote',
  },
]

const saleTypes = [
  {
    value: 'NODE',
    label: 'Node Sale',
  },
  {
    value: 'IDO',
    label: 'IDO Sale',
  },
]

const poolStates  = [
  {
    value: 1,
    label: '(1) Pending',
  },
  {
    value: 2,
    label: '(2) Ready for sale',
  },
  {
    value: 3,
    label: '(3) Sale Ended',
  },
  {
    value: 4,
    label: '(4) Claims Open',
  },
  {
    value: 6,
    label: '(5) Refunds Open',
  },
]

function IdoCreateOrEdit() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [ido, setIdo] = useState();
  const [openEditorModal, setOpenEditorModal] = useState(false);
  const [openClaimsEditorModal, setOpenClaimsEditorModal] = useState(false);
  const [openDeleteIdoModal, setOpenDeleteIdoModal] = useState(false);
  const [openAddStakeTier, setOpenAddStakeTier] = useState(false);

  const [idoName, setIdoName] = useState('');
  const [idoDollarAmount, setIdoDollarAmount] = useState('');
  const [idoStatus, setIdoStatus] = useState('');
  const [idoSaleType, setIdoSaleType] = useState('');

  const [idoEnableDetailsPage, setIdoEnableDetailsPage] = useState(false);
  const [idoShowInProduction, setIdoShowInProduction] = useState(false);
  const [idoSoldOut, setIdoSoldOut] = useState(false);
  const [idoIsClassified, setIdoIsClassified] = useState(false);
  const [idoDisplayHydrazine, setIdoDisplayHydrazine] = useState(false);
  const [isVotedProject, setIsVotedProject] = useState(false);

  const [idoIsArbitrum, setIdoIsArbitrum] = useState(false);
  const [idoIsEthereum, setIdoIsEthereum] = useState(false);
  const [idoIsPolygon, setIdoIsPolygon] = useState(false);
  const [idoIsSolana, setIdoIsSolana] = useState(false);
  const [idoIsSUI, setIdoIsSUI] = useState(false);
  const [idoIsSupra, setIdoIsSupra] = useState(false);
  const [idoIsAvalanche, setIdoIsAvalanche] = useState(false);
  const [idoIsDymension, setIdoIsDymension] = useState(false);
  const [idoIsSkale, setIdoIsSkale] = useState(false);
  const [idoIsBase, setIdoIsBase] = useState(false);

  const [idoDescription, setIdoDescription] = useState('');
  const [idoPair, setIdoPair] = useState('');
  const [idoSymbol, setIdoSymbol] = useState('');
  const [idoSwaprate, setIdoSwaprate] = useState('');
  const [idoFactor, setIdoFactor] = useState('');
  const [idoPoolcap, setIdoPoolcap] = useState('');
  const [idoLaunchcap, setIdoLaunchcap] = useState('');
  const [idoSupply, setIdoSupply] = useState('');
  const [idoParticipants, setIdoParticipants] = useState('');
  const [idoToken, setIdoToken] = useState('');
  const [idoStakingLimit, setIdoStakingLimit] = useState('');

  const [idoIsNodeSale, setIdoIsNodeSale] = useState(false);
  const [idoHydrazinePrice, setIdoHydrazinePrice] = useState('');
  const [idoNodePrice, setIdoNodePrice] = useState('');
  const [nodeQuantity, setNodeQuantity] = useState('');
  const [nodeOGTierActive, setNodeOGTierActive] = useState(false);
  const [nodeStakerTierActive, setNodeStakerTierActive] = useState(false);
  const [nodeFCFSTierActive, setNodeFCFSTierActive] = useState(false);
  const [nodeStakerTierOptions, setNodeStakerTierOptions] = useState([]);
  
  const [nodeOGTierStartTime, setNodeOGTierStartTime] = useState();
  const [nodeOGTierEndTime, setNodeOGTierEndTime] = useState();
  const [nodeStakersTierStartTime, setNodeStakersTierStartTime] = useState();
  const [nodeStakersTierEndTime, setNodeStakersTierEndTime] = useState();
  const [nodeFCFSTierStartTime, setNodeFCFSTierStartTime] = useState();
  const [nodeFCFSTierEndTime, setNodeFCFSTierEndTime] = useState();

  const [idoDiscord, setIdoDiscord] = useState('');
  const [idoMedium, setIdoMedium] = useState('');
  const [idoTelegram, setIdoTelegram] = useState('');
  const [idoTwitter, setIdoTwitter] = useState('');
  const [idoWebsite, setIdoWebsite] = useState('');

  const [idoRegistrationStart, setIdoRegistrationStart] = useState();
  const [idoRegistrationEnd, setIdoRegistrationEnd] = useState();
  const [idoSaleStart, setIdoSaleStart] = useState();
  const [idoSaleEnd, setIdoSaleEnd] = useState();
  const [idoOpenStart, setIdoOpenStart] = useState();
  const [idoOpenNoRestrictionStart, setIdoOpenNoRestrictionStart] = useState();
  const [idoOpenEnd, setIdoOpenEnd] = useState();

  const [idoHTMLDescription, setIdoHTMLDescription] = useState();
  const [idoClaimsDescription, setIdoClaimsDescription] = useState();
  const [idoIcon, setIdoIcon] = useState();
  const [idoBannerImages, setIdoBannerImages] = useState([]);
  const [idoBannerVideos, setIdoBannerVideos] = useState([]);
  const [idoCategory, setIdoCategory] = useState('');
  const [idoHeaderDescription, setIdoHeaderDescription] = useState();
  const [idoDistribution, setIdoDistribution] = useState('');

  const [idoPoolName, setIdoPoolName] = useState('');
  const [idoDevPoolName, setIdoDevPoolName] = useState('');
  const [idoPoolState, setIdoPoolState] = useState(1);
  const [idoInitialUnlock, setIdoInitialUnlock] = useState('');
  const [idoNumberOfTranches, setIdoNumberOfTranches] = useState('');

  const idoDatas = useSelector(state => state.idoData.allIDOs)

  const setIDODefaultData = () => {
    if (id === NEW_IDO_ID) {
      return;
    }

    const selectedIDO = idoDatas.find(ido => ido.id === id);

    setIdoName(selectedIDO.name)
    setIdoDollarAmount(selectedIDO.dollarAmount)
    setIdoStatus(selectedIDO.status)
    setIdoSaleType(selectedIDO.saleType)
    setIdoShowInProduction(selectedIDO.production)
    setIdoEnableDetailsPage(selectedIDO.enableDetailsPage)
    setIdoSoldOut(selectedIDO.soldOut)
    setIdoIsClassified(selectedIDO.isClassified)
    setIdoDisplayHydrazine(selectedIDO.displayHydrazine)
    setIsVotedProject(selectedIDO.isVotedProject)

    setIdoIsEthereum(selectedIDO.isEthereum)
    setIdoIsPolygon(selectedIDO.isPolygon)
    setIdoIsSolana(selectedIDO.isSol)
    setIdoIsArbitrum(selectedIDO.isArbitrum)
    setIdoIsSupra(selectedIDO.isSupra)
    setIdoIsSUI(selectedIDO.isSui)
    setIdoIsAvalanche(selectedIDO?.isAvalanche)
    setIdoIsDymension(selectedIDO?.isDymension)
    setIdoIsSkale(selectedIDO?.isSkale)
    setIdoIsBase(selectedIDO?.isBase);

    setIdoDescription(selectedIDO.description)
    setIdoPair(selectedIDO.pair)
    setIdoSymbol(selectedIDO.symbol)
    setIdoSwaprate(selectedIDO.swaprate)
    setIdoFactor(selectedIDO.factor)
    setIdoPoolcap(selectedIDO.poolcap)
    setIdoLaunchcap(selectedIDO.launchCap)
    setIdoSupply(selectedIDO.supply)
    setIdoParticipants(selectedIDO.participants)
    setIdoToken(selectedIDO.token)
    setIdoStakingLimit(selectedIDO.stakingLimit)

    setIdoDiscord(selectedIDO.discord);
    setIdoMedium(selectedIDO.medium);
    setIdoTelegram(selectedIDO.telegram);
    setIdoTwitter(selectedIDO.twitter);
    setIdoWebsite(selectedIDO.website);

    setIdoRegistrationStart(moment.unix(selectedIDO.registrationStartTime).utc());
    setIdoRegistrationEnd(moment.unix(selectedIDO.registrationEndTime).utc());
    setIdoSaleStart(moment.unix(selectedIDO.salesStartTime).utc());
    setIdoSaleEnd(moment.unix(selectedIDO.salesEndTime).utc());
    setIdoOpenStart(moment.unix(selectedIDO.openSaleStartTime).utc());
    setIdoOpenNoRestrictionStart(moment.unix(selectedIDO.openSaleNoRestrictionStartTime).utc());
    setIdoOpenEnd(moment.unix(selectedIDO.openSaleEndTime).utc());

    setIdoIcon(selectedIDO.icon);
    setIdoHTMLDescription(selectedIDO.HTMLDescription);
    setIdoClaimsDescription(selectedIDO?.claimsDescription);
    setIdoBannerImages(hasValue(selectedIDO.bannerImages) ? selectedIDO.bannerImages.join(',') : []);
    setIdoBannerVideos(hasValue(selectedIDO.bannerVideos) ? selectedIDO.bannerVideos.join(',') : []);
    setIdoCategory(selectedIDO.category);
    setIdoHeaderDescription(selectedIDO.headerDescription);

    setIdoPoolName(selectedIDO.poolname);
    setIdoDevPoolName(selectedIDO.devPoolname);
    setIdoPoolState(selectedIDO.poolstate);
    setIdoInitialUnlock(selectedIDO.initialUnlock);
    setIdoNumberOfTranches(selectedIDO.numberOfTranches);

    setIdoHydrazinePrice(selectedIDO?.hydrazinePrice);
    setIdoNodePrice(selectedIDO?.nodePrice);
    setIdoIsNodeSale(selectedIDO.isNodeSale);
    setNodeQuantity(selectedIDO.nodeQuantity)
    setIdoDistribution(selectedIDO.distribution);
    setNodeOGTierActive(selectedIDO?.nodeTiers?.ogTier?.active);
    setNodeStakerTierActive(selectedIDO?.nodeTiers?.stakerTier?.active);
    setNodeFCFSTierActive(selectedIDO?.nodeTiers?.fcfsTier?.active);

    setNodeOGTierStartTime(moment.unix(selectedIDO?.nodeTiers?.ogTier?.startTime).utc());
    setNodeOGTierEndTime(moment.unix(selectedIDO?.nodeTiers?.ogTier?.endTime).utc());
    setNodeStakersTierStartTime(moment.unix(selectedIDO?.nodeTiers?.stakerTier?.startTime).utc());
    setNodeStakersTierEndTime(moment.unix(selectedIDO?.nodeTiers?.stakerTier?.endTime).utc());
    setNodeFCFSTierStartTime(moment.unix(selectedIDO?.nodeTiers?.fcfsTier?.startTime).utc());
    setNodeFCFSTierEndTime(moment.unix(selectedIDO?.nodeTiers?.fcfsTier?.endTime).utc());

    setIdo(selectedIDO);
  }

  useEffect( ()=>{
    const fetchSaleData = async () => {
      fetch(PROD_API_URL+"/sales/", {
        "method": "POST",
        "headers": {"content-type": "application/json", "accept": "*"},
      }).then(res=>res.json())
        .then((result)=>{
          dispatch(setIdoData(result.data.sales_json.sales))
        });
    }

    if (idoDatas.length === 0) {
      fetchSaleData();
    } else {
      setIDODefaultData();
    }

  },[idoDatas]);

  const saveChanges = async () => {

    try {
      let body= {
        "poolname": idoPoolName,
        "devPoolname": idoDevPoolName,
        "poolstate": idoPoolState,
        "icon": idoIcon,
        "image": hasValue(ido?.image) ? ido.image : '',
        "website": idoWebsite,
        "telegram": idoTelegram,
        "discord": idoDiscord,
        "medium": idoMedium,
        "twitter": idoTwitter,
        "status": idoStatus,
        "description": idoDescription,
        "pair": idoPoolcap,
        "swaprate": idoSwaprate,
        "poolcap": idoPoolcap,
        "dollarAmount": idoDollarAmount,
        "symbol": idoSymbol,
        "registrationStartTime": hasValue(idoRegistrationStart) ? moment(idoRegistrationStart).utc().unix() : ido?.registrationStartTime,
        "registrationEndTime": hasValue(idoRegistrationEnd) ? moment(idoRegistrationEnd).utc().unix() : ido?.registrationEndTime,
        "salesStartTime": hasValue(idoSaleStart) ? moment(idoSaleStart).utc().unix() : ido?.salesStartTime,
        "salesEndTime": hasValue(idoSaleEnd) ?  moment(idoSaleEnd ).utc().unix() : ido?.salesEndTime,
        "openSaleStartTime": hasValue(idoOpenStart) ? moment(idoOpenStart).utc().unix() : ido?.openSaleStartTime,
        "openSaleNoRestrictionStartTime": hasValue(idoOpenNoRestrictionStart) ? moment(idoOpenNoRestrictionStart).utc().unix() : ido?.openSaleNoRestrictionStartTime,
        "openSaleEndTime": hasValue(idoOpenEnd) ? moment(idoOpenEnd).utc().unix() : ido?.openSaleEndTime,
        "supply": idoSupply,
        "launchCap": idoLaunchcap,
        "participants": idoParticipants,
        "factor": idoFactor,
        "token": idoToken,
        "stakingRule": hasValue(ido?.stakingRule) ? ido?.stakingRule : '',
        "hydrazineDisplay": idoDisplayHydrazine,
        "stakingLimit": idoStakingLimit,
        "countries": hasValue(ido?.countries) ? ido?.countries : DEFAULT_IDO_COUNTRIES,
        "production": idoShowInProduction,
        "enableDetailsPage": idoEnableDetailsPage,
        "isEthereum": idoIsEthereum,
        "isSol": idoIsSolana,
        "isPolygon": idoIsPolygon,
        "isArbitrum": idoIsArbitrum,
        "isSupra": idoIsSupra,
        "isSui": idoIsSUI,
        "isAvalanche": idoIsAvalanche,
        "isDymension": idoIsDymension,
        "isSkale": idoIsSkale,
        "isBase": idoIsBase,
        "isClassified": idoIsClassified,
        "isVotedProject": isVotedProject,
        "saleType": idoSaleType,
        "bannerImages": hasValue(idoBannerImages) ? idoBannerImages.split(',') : '',
        "bannerVideos": hasValue(idoBannerVideos) ? idoBannerVideos.split(',') : '',
        "HTMLDescription": idoHTMLDescription,
        "claimsDescription": idoClaimsDescription,
        "headerDescription" : idoHeaderDescription,
        "category" : idoCategory,
        "soldOut": idoSoldOut,
        "name": idoName,
        "initialUnlock": idoInitialUnlock,
        "numberOfTranches": idoNumberOfTranches,
        "hydrazinePrice": idoHydrazinePrice,
        "nodePrice": idoNodePrice,
        "nodeQuantity": nodeQuantity,
        "isNodeSale": idoIsNodeSale,
        "nodeTiers": {
          "ogTier": {
            "active": nodeOGTierActive,
            "startTime": moment(nodeOGTierStartTime).unix(),
            "endTime": moment(nodeOGTierEndTime).unix(),
          },
          "stakerTier": {
            "active": nodeStakerTierActive,
            "startTime": moment(nodeStakersTierStartTime).unix(),
            "endTime":  moment(nodeStakersTierEndTime).unix(),
          },
          "fcfsTier": {
            "active": nodeFCFSTierActive,
            "startTime": moment(nodeFCFSTierStartTime).unix(),
            "endTime": moment(nodeFCFSTierEndTime).unix(),
          },
        },
        "distribution": idoDistribution,
      };

      if (hasValue(id) && id !== NEW_IDO_ID) {
        body.id = ido.id;
      }

      await fetch(PROD_API_URL + "/ido/createOrEditIdo", {
        "method": "POST",
        "headers": {"content-type": "application/json", "accept": "*"},
        "body": JSON.stringify(body)
      }).then(r => {
        if (r.ok && r.status === 200) {
          if (id === NEW_IDO_ID) {
            toast('IDO Succefully Created!', successToast)
            history.push(PATH_IDO_ADMINISTRATION);
          } else {
            toast('IDO Succefully updated!', successToast)
          }
        } else {
          toast('ERROR Saving IDO', errorToast)
        }
      })
    } catch (e) {
      toast('ERROR Saving IDO: ' + e, errorToast)
    }

  }

  const deleteIdo = async () => {
    try {
      await fetch(PROD_API_URL + "/ido/deleteIdo", {
        "method": "POST",
        "headers": {"content-type": "application/json", "accept": "*"},
        "body": JSON.stringify({ id: ido.id})
      }).then(r => {
        if (r.ok && r.status === 200) {
          toast('IDO Succefully Deleted!', successToast)
          history.push(PATH_IDO_ADMINISTRATION);
        } else {
          toast('ERROR Saving IDO', errorToast)
        }
      })
    } catch (e) {
      toast('ERROR Deleting IDO: ' + e, errorToast)
    } finally {
      setOpenDeleteIdoModal(false);
    }
  }

  const setSaleTypeWrapper = (saleValue) => {
    setIdoIsNodeSale(saleValue === 'NODE');
  }

  return (
    <main id="home" key="home" className={styles.mainIdoContainer}>
      {openEditorModal && <DraftEditor
        setClose={() => setOpenEditorModal(false)}
        setFinishedHtml={setIdoHTMLDescription}
        valueHTML={idoHTMLDescription} />}
      {openClaimsEditorModal && <DraftEditor
        setClose={() => setOpenClaimsEditorModal(false)}
        setFinishedHtml={setIdoClaimsDescription}
        valueHTML={idoClaimsDescription} />}
      {(ido || id === NEW_IDO_ID) && <div className={styles.idoAdminContainer}>
        <div className={styles.idoInputContainer}>
          <div className={styles.editRow}>
            <TextInput value={idoName} label="Name" setValue={setIdoName}/>
            <TextInput value={idoDollarAmount} label="Dollar Amount" setValue={setIdoDollarAmount}/>
            <TextInput value={idoCategory} label="Category" setValue={setIdoCategory}/>
            <div className={styles.buttonContainer}>
              <ImageSubmit base64Value={idoIcon} setBase64={setIdoIcon} useBase64 text="IDO Icon"/>
            </div>
          </div>
          <div className={styles.editRow}>
            <div className={styles.checkboxContainer}>
              <TextField
                id="outlined-select-currency"
                select
                label="Select"
                defaultValue={idoStatus}
                helperText="Select IDO Status">
                {idoStatuses.map((option) => (
                  <MenuItem key={option.value} value={option.value} onClick={() => setIdoStatus(option.value)}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className={styles.checkboxContainer}>
              <TextField
                id="outlined-select-currency"
                select
                label="Select"
                defaultValue={idoSaleType}
                helperText="Select IDO Sale Type">
                {idoTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value} onClick={() => setIdoSaleType(option.value)}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className={styles.checkboxContainer}>
              <FormControlLabel
                control={<Checkbox checked={idoShowInProduction}
                                   onChange={event => setIdoShowInProduction(event.target.checked)}/>}
                label="Show in production"
                labelPlacement="top"
              />
            </div>
            <div className={styles.checkboxContainer}>
              <FormControlLabel
                control={<Checkbox checked={idoEnableDetailsPage}
                                   onChange={event => setIdoEnableDetailsPage(event.target.checked)}/>}
                label="Enable Details Page"
                labelPlacement="top"
              />
            </div>
            <div className={styles.checkboxContainer}>
              <FormControlLabel
                value={idoSoldOut}
                control={<Checkbox checked={idoSoldOut} onChange={event => setIdoSoldOut(event.target.checked)}/>}
                label="Has IDO Sold Out"
                labelPlacement="top"
              />

            </div>
            <div className={styles.checkboxContainer}>
              <FormControlLabel
                control={<Checkbox checked={idoIsClassified}
                                   onChange={event => setIdoIsClassified(event.target.checked)}/>}
                label="Is IDO Classified"
                labelPlacement="top"
              />
            </div>
            <div className={styles.checkboxContainer}>
              <FormControlLabel
                control={<Checkbox checked={idoDisplayHydrazine}
                                   onChange={event => setIdoDisplayHydrazine(event.target.checked)}/>}
                label="Display Total Contributed Hydrazine"
                labelPlacement="top"
              />
            </div>
            <div className={styles.checkboxContainer}>
              <FormControlLabel
                control={<Checkbox checked={isVotedProject}
                                   onChange={event => setIsVotedProject(event.target.checked)}/>}
                label="Is Community Voted Project"
                labelPlacement="top"
              />
            </div>
          </div>

          <div className={styles.textAreaInputRow}>
            <div style={{flex: '2'}}>
              <TextAreaInput
                value={idoDescription}
                setValue={setIdoDescription}
                rows={4}
                label="Card Description"/>
            </div>
            <div style={{flex: '1'}}>
              <TextAreaInput
                value={idoHeaderDescription}
                setValue={setIdoHeaderDescription}
                rows={4} l
                label="Short Header Description"/>
            </div>
          </div>
          <TextAreaInput value={idoBannerImages} setValue={setIdoBannerImages}
                         label="Add Banner Images Links (Comma ',' seperated if multiple)"/>
          <TextAreaInput value={idoBannerVideos} setValue={setIdoBannerVideos}
                         label="Add Banner Video Links (Comma ',' seperated if multiple)"/>
          <div className={styles.editRow}>
            <TextInput value={idoSupply} label="Supply" setValue={setIdoSupply}/>
            <TextInput value={idoDiscord} label="Discord" setValue={setIdoDiscord}/>
            <TextInput value={idoMedium} label="Medium" setValue={setIdoMedium}/>
          </div>
          <div className={styles.editRow}>
            <TextInput value={idoTelegram} label="Telegram" setValue={setIdoTelegram}/>
            <TextInput value={idoTwitter} label="Twitter" setValue={setIdoTwitter}/>
            <TextInput value={idoWebsite} label="Website" setValue={setIdoWebsite}/>
          </div>
          <h3>SALE SPECIFIC</h3>
          <div className={styles.editRow}>
            <div className={styles.checkboxContainer}>
              <TextField
                id="outlined-select-currency"
                select
                label="Select"
                defaultValue={idoIsNodeSale ? 'NODE' : 'IDO'}
                helperText="Select Sale Type">
                {saleTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value} onClick={() => setSaleTypeWrapper(option.value)}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
          {!idoIsNodeSale && <div className={styles.editRow}>
            <TextInput value={idoPair} label="Pair" setValue={setIdoPair}/>
            <TextInput value={idoSymbol} label="Symbol" setValue={setIdoSymbol}/>
            <TextInput value={idoSwaprate} label="SwapRate" setValue={setIdoSwaprate}/>
          </div>}
          {!idoIsNodeSale && <div className={styles.editRow}>
            <TextInput value={idoFactor} label="Factor" setValue={setIdoFactor}/>
            <TextInput value={idoPoolcap} label="Pool MarketCap" setValue={setIdoPoolcap}/>
            <TextInput value={idoLaunchcap} label="Launch MarketCap" setValue={setIdoLaunchcap}/>
          </div>}

          {!idoIsNodeSale && <div className={styles.editRow}>
            <TextInput value={idoParticipants} label="Participants" setValue={setIdoParticipants}/>
            <TextInput value={idoToken} label="Token" setValue={setIdoToken}/>
            <TextInput value={idoStakingLimit} label="Staking Limits" setValue={setIdoStakingLimit}/>
          </div>}
          {!idoIsNodeSale && <div className={styles.editRow}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                value={idoRegistrationStart}
                onChange={(value) => setIdoRegistrationStart(value)}
                label="Registration Start"/>
              <DateTimePicker
                value={idoRegistrationEnd}
                onChange={setIdoRegistrationEnd}
                label="Registration End"/>
              <DateTimePicker
                value={idoSaleStart}
                onChange={setIdoSaleStart}
                label="Sale Start"/>
              <DateTimePicker
                value={idoSaleEnd}
                onChange={setIdoSaleEnd}
                label="Sale End"/>
              <DateTimePicker
                value={idoOpenStart}
                onChange={setIdoOpenStart}
                label="Open Sale Start"/>
              <DateTimePicker
                value={idoOpenNoRestrictionStart}
                onChange={setIdoOpenNoRestrictionStart}
                label="Open Sale No Restrictions Start"/>
              <DateTimePicker
                value={idoOpenEnd}
                onChange={setIdoOpenEnd}
                label="Open Sale End"/>
            </LocalizationProvider>

          </div>}
          {idoIsNodeSale && <div className={styles.editRow}>
            <div className={styles.checkboxContainer}>
              <FormControlLabel
                control={<Checkbox checked={idoIsNodeSale}
                                   onChange={event => setIdoIsNodeSale(event.target.checked)}/>}
                label="Node Sale"
                labelPlacement="Top"
              />
            </div>
            <div className={styles.checkboxContainer}>
              <FormControlLabel
                control={<Checkbox checked={nodeOGTierActive}
                                   onChange={event => setNodeOGTierActive(event.target.checked)}/>}
                label="Enable OG Stage"
                labelPlacement="Top"
              />
            </div>
            <div className={styles.checkboxContainer}>
              <FormControlLabel
                control={<Checkbox checked={nodeStakerTierActive}
                                   onChange={event => setNodeStakerTierActive(event.target.checked)}/>}
                label="Enable Staker Stage"
                labelPlacement="Top"
              />
            </div>
            <div className={styles.checkboxContainer}>
              <FormControlLabel
                control={<Checkbox checked={nodeFCFSTierActive}
                                   onChange={event => setNodeFCFSTierActive(event.target.checked)}/>}
                label="Enable FCFS Tier"
                labelPlacement="Top"
              />
            </div>
            <TextInput value={nodeQuantity} label="Node Quantity" setValue={setNodeQuantity}/>
          </div>}
          {idoIsNodeSale && <div className={styles.editRow}>
            <TextInput value={idoHydrazinePrice} label="Hydrazine Price" setValue={setIdoHydrazinePrice}/>
            <TextInput value={idoNodePrice} label="Node Price" setValue={setIdoNodePrice}/>
            <TextInput value={idoDistribution} label="Distribution" setValue={setIdoDistribution}/>
          </div>}
          {idoIsNodeSale && <div className={styles.editRow}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                value={nodeOGTierStartTime}
                onChange={(value) => setNodeOGTierStartTime(value)}
                label="OG Stage Start"/>
              <DateTimePicker
                value={nodeOGTierEndTime}
                onChange={setNodeOGTierEndTime}
                label="OG Stage End"/>
              <DateTimePicker
                value={nodeStakersTierStartTime}
                onChange={setNodeStakersTierStartTime}
                label="Stakers Stage Start"/>
              <DateTimePicker
                value={nodeStakersTierEndTime}
                onChange={setNodeStakersTierEndTime}
                label="Staker Stage End"/>
              <DateTimePicker
                value={nodeFCFSTierStartTime}
                onChange={setNodeFCFSTierStartTime}
                label="FCFS Stage Start"/>
              <DateTimePicker
                value={nodeFCFSTierEndTime}
                onChange={setNodeFCFSTierEndTime}
                label="FCFS Stage End"/>
            </LocalizationProvider>
          </div>}
          <h3>VESTING (Only add values here if you want to override the contract)</h3>
          <div className={styles.editRow}>
            <TextInput value={idoInitialUnlock} label="Initial Unlock" setValue={setIdoInitialUnlock}/>
            <TextInput value={idoNumberOfTranches} label="# of Tranches" setValue={setIdoNumberOfTranches}/>
          </div>
          <h3>BLOCKCHAIN (Pick one)</h3>
          <div className={styles.editRow}>
            <div className={styles.checkboxContainer}>
              <FormControlLabel
                control={<Checkbox checked={idoIsSolana} onChange={event => setIdoIsSolana(event.target.checked)}/>}
                label="Solana"
                labelPlacement="top"
              />
            </div>
            <div className={styles.checkboxContainer}>
              <FormControlLabel
                value={idoSoldOut}
                control={<Checkbox checked={idoIsEthereum} onChange={event => setIdoIsEthereum(event.target.checked)}/>}
                label="Ethereum"
                labelPlacement="top"
              />
            </div>
            <div className={styles.checkboxContainer}>
              <FormControlLabel
                control={<Checkbox checked={idoIsPolygon} onChange={event => setIdoIsPolygon(event.target.checked)}/>}
                label="Polygon"
                labelPlacement="top"
              />
            </div>
            <div className={styles.checkboxContainer}>
              <FormControlLabel
                control={<Checkbox checked={idoIsArbitrum} onChange={event => setIdoIsArbitrum(event.target.checked)}/>}
                label="Arbitrum"
                labelPlacement="top"
              />
            </div>
            <div className={styles.checkboxContainer}>
              <FormControlLabel
                value={idoSoldOut}
                control={<Checkbox checked={idoIsSupra} onChange={event => setIdoIsSupra(event.target.checked)}/>}
                label="SUPRA"
                labelPlacement="top"
              />
            </div>
            <div className={styles.checkboxContainer}>
              <FormControlLabel
                control={<Checkbox checked={idoIsSUI} onChange={event => setIdoIsSUI(event.target.checked)}/>}
                label="SUI"
                labelPlacement="top"
              />
            </div>
            <div className={styles.checkboxContainer}>
              <FormControlLabel
                control={<Checkbox checked={idoIsAvalanche}
                                   onChange={event => setIdoIsAvalanche(event.target.checked)}/>}
                label="Avalanche"
                labelPlacement="top"
              />
            </div>
            <div className={styles.checkboxContainer}>
              <FormControlLabel
                control={<Checkbox checked={idoIsDymension}
                                   onChange={event => setIdoIsDymension(event.target.checked)}/>}
                label="Dymension"
                labelPlacement="top"
              />
            </div>
            <div className={styles.checkboxContainer}>
              <FormControlLabel
                control={<Checkbox checked={idoIsSkale}
                                   onChange={event => setIdoIsSkale(event.target.checked)}/>}
                label="Skale"
                labelPlacement="top"
              />
            </div>
            <div className={styles.checkboxContainer}>
              <FormControlLabel
                control={<Checkbox checked={idoIsBase}
                                   onChange={event => setIdoIsBase(event.target.checked)}/>}
                label="BASE"
                labelPlacement="top"
              />
            </div>
          </div>

          <h3>DEVELOPER OPTION</h3>
          <div className={styles.editRow}>
            <TextInput value={idoPoolName} label="Production Poolname" setValue={setIdoPoolName}/>
            <div className={styles.selectInputContainer}>
              <TextField
                select
                label="PoolState"
                defaultValue={idoPoolState}
                helperText="Select PoolState">
                {poolStates.map((option) => (
                  <MenuItem key={option.value} value={option.value} onClick={() => setIdoPoolState(option.value)}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <TextInput value={idoDevPoolName} label="Dev Poolname" setValue={setIdoDevPoolName}/>

          </div>
        </div>
        <div className={styles.bottomActionContainer}>
          <Button
            className={styles.deleteButton}
            buttonType={BUTTON_TYPE_SECONDARY}
            disabled={!hasValue(id) || id === NEW_IDO_ID}
            text="DELETE"
            onClick={() => setOpenDeleteIdoModal(true)}/>
          <div className={styles.flexGrower}/>
          <div style={{marginRight: '64px'}}>
            <Button buttonType={BUTTON_TYPE_SECONDARY} text="Project Information Editor"
                    onClick={() => setOpenEditorModal(true)}/>
          </div>
          <Button buttonType={BUTTON_TYPE_SECONDARY} text="Reset" onClick={() => setIDODefaultData()}/>
          <Button buttonType={BUTTON_TYPE_SECONDARY} text="Save Changes" onClick={saveChanges}/>
        </div>
      </div>}

      {openDeleteIdoModal && <div className={styles.deleteIdoModalOverlay}>
        <div className={styles.deleteIdoModal}>
          <span>Are you sure you want to delete this IDO?</span>
          <div className={styles.deleteRow}>
            <Button buttonType={BUTTON_TYPE_SECONDARY} text="CANCEL"
                    onClick={() => setOpenDeleteIdoModal(false)}/>
            <Button
              className={styles.deleteButton}
              buttonType={BUTTON_TYPE_SECONDARY}
              text="DELETE"
              onClick={deleteIdo}/>
          </div>
        </div>
      </div>}
    </main>

  );
}

export default IdoCreateOrEdit;
