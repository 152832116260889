import React, {useState} from "react";
import styles from  "./Button.module.scss";
import { useSelector } from "react-redux";
import { useCallback } from "react";

export const BUTTON_TYPE_PRIMARY = 'BUTTON_TYPE_PRIMARY';
export const BUTTON_TYPE_PRIMARY_BRIGHT = 'BUTTON_TYPE_PRIMARY_BRIGHT';
export const BUTTON_TYPE_SECONDARY = 'BUTTON_TYPE_SECONDARY';
export const BUTTON_TYPE_NO_STYLE = 'BUTTON_TYPE_NO_STYLE';

function Button(props) {
    const {
        text,
        Icon,
        IconActive,
        LeftIcon,
        buttonType = BUTTON_TYPE_PRIMARY,
        disabled = false,
        isProgressButton = false,
        muteHover = false,
        onClick,
        className,
        htmlType,
        dataTip,
        tooltip,
    } = props;

    const [hover, setHover] = useState(false);

    function buttonClassnameGenerator() {
        let className;
        switch (buttonType) {
            case BUTTON_TYPE_PRIMARY:
                className = styles.primary;
                break;
            case BUTTON_TYPE_PRIMARY_BRIGHT:
                className = styles.primaryLight;
                break;
            case BUTTON_TYPE_SECONDARY: 
                className = styles.secondary;
                break;
            case BUTTON_TYPE_NO_STYLE: 
                className = styles.noStyle;
                break;
            default: 
                break;

        }
        return className;
    }

    const _onClick = () => {
        if (onClick && !disabled) {
            onClick();
        }
    }

    const _onHover = useCallback(() => {
        setHover(true);
    }, [isProgressButton, disabled, muteHover])

    const renderIcon = () => {
        if (!Icon) {
            return;
        }
        if (hover && IconActive) {
            return <IconActive className={styles.buttonIcon} />;
        }

        return <Icon className={styles.buttonIcon} />
    }

    const buttonClassname = buttonClassnameGenerator();

  return (
      <button
        onClick={() => _onClick()}
        onMouseEnter={_onHover}
        onMouseLeave={() => setHover(false)}
        className={`${buttonClassname} ${className}`}
        type={htmlType}
        data-for={dataTip}
        data-tip={tooltip}
        disabled={disabled}>
          {LeftIcon && <LeftIcon className={`${styles.buttonIcon} ${styles.leftIcon}`} /> }
          {text}
          {renderIcon()}
      </button>
  );
}

export default Button;
