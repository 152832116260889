import React, { useState } from "react";
import styles from './Leftnav.module.scss'
import {
  PATH_HOME,
  PATH_IDO_ADMINISTRATION, PATH_PROJECT_INTEREST,
  PATH_RAFFLE_ADMINISTRATION,
  PATH_STAKERS_LIST,
  PATH_USER_ADMINISTRATION
} from "../../App";
import StarLaunchLogo from "../../images/svg/logo.svg";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";

function Leftnav() {
  const history = useHistory();

  return (
    <div className="sidebar" onClick={e => e.stopPropagation()}>
      <nav
        id="sidebarMenu"
        className={styles.navigationBar}
      >
        <div className="logoContainer" onClick={() => history.push(PATH_HOME)}>
          <StarLaunchLogo className="logo"/>
          <span className="logoText">StarLaunch</span>
        </div>
        <div className={styles.navigationItem} onClick={() => history.push(PATH_HOME)}>
          HOME
        </div>
        <div className={styles.navigationItem} onClick={() => history.push(PATH_IDO_ADMINISTRATION)}>
          IDO ADMINISTRATION
        </div>
        <div className={styles.navigationItem} onClick={() => history.push(PATH_STAKERS_LIST)}>
          STAKER LIST
        </div>
        <div className={styles.navigationItem} onClick={() => history.push(PATH_USER_ADMINISTRATION)}>
          USER ADMINISTRATION
        </div>
        <div className={styles.navigationItem} onClick={() => history.push(PATH_PROJECT_INTEREST)}>
          PROJECT INTEREST
        </div>
        <div className={styles.navigationItem} onClick={() => history.push(PATH_RAFFLE_ADMINISTRATION)}>
          RAFFLE ADMINISTRATION
        </div>
      </nav>
    </div>
  );
}

export default Leftnav;
