export const errorToast = {
    duration: 5000,
    position: 'bottom-left',
    style: {background: "linear-gradient( 180deg,rgb(68, 18, 3) 55.75%,rgba(82, 22, 22, 0.7) 100%)",
    color: "#FFF"},
    className: 'error-toast',
    icon: '⚠',
};

export const informationToast = {
    position: 'bottom-left',
    style: {background: "#F77F00",
        color: "#FFF"},
    className: 'error-toast',
    icon: '⚠',
};

export const successToast = {
    duration: 6000,
    position: 'bottom-left',
    style: {background: "linear-gradient( 180deg,rgb(3, 71, 20) 55.75%,rgba(22, 82, 57, 0.7) 100%)",
              color: "#FFF"},
    className: 'success-toast',
    icon: '✓',
  };
