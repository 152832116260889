import { createSlice } from '@reduxjs/toolkit'

export const idoData = createSlice({
    name: 'idoData',
    initialState: {
        allIDOs: [],
    },
    reducers: {
        setIdoData: (state, data) => {
            const idoPayload = data.payload;
            state.allIDOs = idoPayload
        },
    },
})

export const { setIdoData } = idoData.actions

export default idoData.reducer
