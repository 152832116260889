import {BN, Program, web3 } from "@coral-xyz/anchor";
import {PublicKey, SYSVAR_RENT_PUBKEY, Transaction, SystemProgram, Keypair, Signer } from "@solana/web3.js";
import {Buffer} from "buffer";
import {ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID} from "@solana/spl-token";
const COMMITMENT = "confirmed";
const {
  AnchorUtils,
  ON_DEMAND_DEVNET_PID,
  Randomness,
  Queue,
  asV0Tx,
  getDefaultDevnetQueue,
  getDefaultQueue,
  getProgramId
} = require("@switchboard-xyz/on-demand");
import starRaffleIdl from '../../web3/idl/star_raffle.json';
import * as CONSTANTS from "../../constants/constants";
import {getEnvironment} from "../../helpers/helper";

const PROGRAM_NAME = "star_raffle"
const devProgramId = '76vqHd46Fza9PbjBk46w8wKdgya47UtzbsgMfVFbNNxm'
const prodProgramId = 'HHt8CPV9DKSqiKdd3xBNsJ2BsJhayCGLPuUshpwpL2Q8';

export const getRaffleProgramData = (provider) => {
  const idl = starRaffleIdl;

  const starRaffleProgram = new Program(idl, provider);

  return {
    program: starRaffleProgram,
    programName: PROGRAM_NAME,
    programId: getEnvironment() === CONSTANTS.PRODUCTION ? new PublicKey(prodProgramId) : new PublicKey(devProgramId),
  }
}
export const checkIfRaffleUser = async ({ provider, connection }) => {
  const {
    program,
    programName,
    programId
} = getRaffleProgramData(provider);

  const [raffleUser, raffleUserBump] = PublicKey.findProgramAddressSync(
    [
      provider.wallet.publicKey.toBuffer(),
      Buffer.from(programName),
      Buffer.from("raffle_user")
    ],
    programId
  );

  const raffleInit = await connection.getBalance(raffleUser);

  return raffleInit > 0;
}

export const getRaffleManagers = async ({provider, connection, raffleName}) => {
  try {
    const { program, programId } = getRaffleProgramData(provider);

    const [raffle] = PublicKey.findProgramAddressSync(
      [Buffer.from(raffleName)],
      programId
    );

    const raffleAccount = await program.account.raffle.fetch(raffle);
    
    return {
      primaryManager: raffleAccount.manager.toString(),
      additionalManagers: raffleAccount.managers.map(manager => manager.toString())
    };
  } catch (err) {
    console.error('Failed to get raffle managers:', err);
    throw err;
  }
};

export const addRaffleManager = async ({provider, connection, raffleName, newManagerAddress}) => {
  try {
    const { program, programId } = getRaffleProgramData(provider);

    const [raffle] = PublicKey.findProgramAddressSync(
      [Buffer.from(raffleName)],
      programId
    );

    const ix = await program.methods
      .addManager(new PublicKey(newManagerAddress))
      .accounts({
        owner: provider.wallet.publicKey,
        raffle,
      })
      .instruction();

    const transaction = new Transaction();
    const { blockhash } = await connection.getLatestBlockhash();
    
    transaction.recentBlockhash = blockhash;
    transaction.feePayer = provider.wallet.publicKey;
    transaction.add(ix);

    return await provider.sendAndConfirm(transaction);

  } catch (err) {
    console.error('Failed to add manager:', err);
    throw err;
  }
};

export const removeRaffleManager = async ({provider, connection, raffleName, managerAddress}) => {
  try {
    const { program, programId } = getRaffleProgramData(provider);

    const [raffle] = PublicKey.findProgramAddressSync(
      [Buffer.from(raffleName)],
      programId
    );

    const ix = await program.methods
      .removeManager(new PublicKey(managerAddress))
      .accounts({
        owner: provider.wallet.publicKey,
        raffle,
      })
      .instruction();

    const transaction = new Transaction();
    const { blockhash } = await connection.getLatestBlockhash();
    
    transaction.recentBlockhash = blockhash;
    transaction.feePayer = provider.wallet.publicKey;
    transaction.add(ix);

    return await provider.sendAndConfirm(transaction);

  } catch (err) {
    console.error('Failed to remove manager:', err);
    throw err;
  }
};

export const isRaffleManager = async ({provider, connection, raffleName, address}) => {
  try {
    const { program, programId } = getRaffleProgramData(provider);

    const [raffle] = PublicKey.findProgramAddressSync(
      [Buffer.from(raffleName)],
      programId
    );

    const raffleAccount = await program.account.raffle.fetch(raffle);
    const addressPubkey = new PublicKey(address);

    return (
      raffleAccount.primary_manager.equals(addressPubkey) || 
      raffleAccount.managers.some(m => m.equals(addressPubkey))
    );
  } catch (err) {
    console.error('Failed to check manager status:', err);
    return false;
  }
};


export const createRaffleUser = async ({ provider, connection }) => {
  const {
    program,
    programName,
    programId
  } = getRaffleProgramData(provider);

  const [mainData, mainDataBump] = await PublicKey.findProgramAddress(
    [Buffer.from(programName)],
    programId
  );
  const [raffleUser, raffleUserBump] = await PublicKey.findProgramAddress(
    [
      provider.wallet.publicKey.toBuffer(),
      Buffer.from(programName),
      Buffer.from("raffle_user")
    ],
    programId
  );
  const transaction = new Transaction();

  transaction.add(
    program.instruction.createUser(
      raffleUserBump,
      {
        accounts: {
          owner: provider.wallet.publicKey,
          userAuthority: provider.wallet.publicKey,
          raffleUser: raffleUser,
          mainData: mainData,
          systemProgram: SystemProgram.programId,
          rent: SYSVAR_RENT_PUBKEY,
        }
      }
    ));

  const signature = await provider.wallet.sendTransaction(transaction, connection);

  return await connection.confirmTransaction(signature, 'processed');
}

export const createRaffle = async ({ connection, provider, raffleData }) => {
  try {
    const { program, programId } = getRaffleProgramData(provider);

    const [raffle, raffleBump] = PublicKey.findProgramAddressSync(
        [Buffer.from(raffleData.raffleName)],
        programId
    );

    const [raffleUser] = PublicKey.findProgramAddressSync(
        [
          provider.wallet.publicKey.toBuffer(),
          Buffer.from("star_raffle"),
          Buffer.from("raffle_user")
        ],
        programId
    );

    const [raffleVault] = PublicKey.findProgramAddressSync(
        [
          Buffer.from(raffleData.raffleName),
          Buffer.from("raffle_vault")
        ],
        programId
    );

    const ix = await program.methods
        .createRaffle(
            raffleBump,
            raffleData.raffleName,
            new BN(raffleData.maxTicketRaffle),
            new BN(raffleData.maxWinners),
            new BN(raffleData.contributionStartTime),
            new BN(raffleData.contributionEndTime),
            new PublicKey(raffleData.tokenMint),
            new BN(raffleData.ticketPrice),
            new BN(raffleData.maxTicketUser),
        )
        .accounts({
          owner: provider.wallet.publicKey,
          raffle,
          raffleUser,
          currency: new PublicKey(raffleData.tokenMint),
          raffleVault,
          systemProgram: SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
          rent: SYSVAR_RENT_PUBKEY,
        }).rpc();


    return ix;

  } catch (err) {
    console.error('Failed to create raffle:', err);
    if (err.logs) {
      console.error('Program logs:', err.logs);
    }
    throw err;
  }
};

export const updateRaffle = async ({ connection, provider, raffleData }) => {
  try {
    const { program, programId } = getRaffleProgramData(provider);

    const [raffle] = PublicKey.findProgramAddressSync(
        [Buffer.from(raffleData.raffleName)],
        programId
    );
    const [raffleUser] = PublicKey.findProgramAddressSync(
        [
          provider.wallet.publicKey.toBuffer(),
          Buffer.from("star_raffle"),
          Buffer.from("raffle_user")
        ],
        programId
    );

    const tx = await program.methods
        .updateRaffle(
            new BN(Number(raffleData.maxTicketRaffle)),
            new BN(Number(raffleData.maxWinners)),
            new BN(raffleData.contributionStartTime),
            new BN(raffleData.contributionEndTime),
            new PublicKey(raffleData.tokenMint),
            new BN(Number(raffleData.ticketPrice)),
            new BN(Number(raffleData.maxTicketUser))
        )
        .accounts({
          owner: provider.wallet.publicKey,
          raffle,
          raffleUser,
          currency: new PublicKey(raffleData.tokenMint),
          systemProgram: SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
          rent: SYSVAR_RENT_PUBKEY,
        })
        .rpc();

    return tx;
  } catch (err) {
    console.error('Failed to update raffle:', err);
    throw err;
  }
};

export const pickWinner = async ({ provider, connection, raffleName }) => {

  try{
    const {
      program,
      programId,
      programName
    } = getRaffleProgramData(provider);

    const [mainData, mainDataBump] = await PublicKey.findProgramAddress(
        [Buffer.from(programName)],
        programId
    );
    const [raffle, raffleBump] = await PublicKey.findProgramAddress(
        [Buffer.from(raffleName)],
        programId
    );
    const [participant, participantBump] = await PublicKey.findProgramAddress(
        [
          provider.wallet.publicKey.toBuffer(),
          Buffer.from(raffleName),
          Buffer.from("raffle_participant")
        ],
        programId
    );

    // create randomness account and initialise it
    const queueAccount = await getDefaultQueue(connection.rpcEndpoint);
    const sbProgramId = await getProgramId(connection);  // EYiAmGSdsQTuCw413V5BzaruWuCCSDgTPtBGvLkXHbe7

    const sbIdl = await Program.fetchIdl(sbProgramId, provider);
    const sbProgram = new Program(sbIdl, provider);
    const rngKp = Keypair.generate();
    const [randomness, ix] = await Randomness.create(sbProgram, rngKp, queueAccount.pubkey);
    
    console.log("Randomness account", sbProgramId.toString(), randomness.pubkey.toString(), queueAccount.pubkey.toString(), provider.wallet.publicKey.toString());
    const createRandomnessTx = await asV0Tx({
      connection: sbProgram.provider.connection,
      ixs: [ix],
      payer: provider.wallet.publicKey,
      signers: [rngKp]
    });
    const txOpts = {
      commitment: "processed",
      skipPreflight: false,
      maxRetries: 0,
    };
    const signedRandomnessTx = await provider.wallet.signTransaction(createRandomnessTx, txOpts);
    const sig1 = await provider.connection.sendRawTransaction(signedRandomnessTx.serialize());

    // const sig1 = await provider.connection.sendTransaction(createRandomnessTx, txOpts);
    await provider.connection.confirmTransaction(sig1, COMMITMENT);
    console.log(
        "  Transaction Signature for randomness account creation: ",
        sig1
    );

    // With your randomness account at the ready, commit to the oracle's prediction. This is where you formally ask for the outcome based on a future slot.
    const commitIx = await randomness.commitIx(queueAccount.pubkey);
    const pickWinnerIx = await program.instruction.pickWinner(
        randomness.pubkey, {
          accounts: {
            signer: provider.wallet.publicKey,
            raffle: raffle,
            participant: participant,
            randomnessAccountData: randomness.pubkey,
            systemProgram: SystemProgram.programId,
            rent: SYSVAR_RENT_PUBKEY
        }});

    const commitTx = await asV0Tx({
      connection: sbProgram.provider.connection,
      ixs: [commitIx, pickWinnerIx],
      payer: provider.wallet.publicKey
    });

    const signedCommitTx = await provider.wallet.signTransaction(commitTx, txOpts);
    const sig4 = await provider.connection.sendRawTransaction(signedCommitTx.serialize());
    await provider.connection.confirmTransaction(sig4, COMMITMENT);
    
    // reveal
    const revealIx = await randomness.revealIx();
    const finalizeWinnerIx = await program.instruction.finalizeWinner(
         {
           accounts: {
             signer: provider.wallet.publicKey,
             raffle: raffle,
             participant: participant,
             randomnessAccountData: randomness.pubkey,
             systemProgram: SystemProgram.programId,
             rent: SYSVAR_RENT_PUBKEY
           },
         }
       );
    const commitFinalizeTx = await asV0Tx({
         connection: sbProgram.provider.connection,
         ixs: [revealIx, finalizeWinnerIx],
         payer: provider.wallet.publicKey
       });
  
      
    try {
        const signedCommitTx = await provider.wallet.signTransaction(commitFinalizeTx, txOpts);
        const signature = await provider.connection.sendRawTransaction(signedCommitTx.serialize());
        await provider.connection.confirmTransaction(signature, COMMITMENT);
        console.log("Transaction Signature commitTx", signature);
        console.log(signature)
    } catch (error) {
        console.error("Unexpected Error:", error);
    }
  } catch(e) {
    console.error(e);
    throw e
  }
}


export const solanaDefaultToken = {
  label: 'Wrapped SOL',
  name: 'Solana',
  key: 'SOL',
  value: 'So11111111111111111111111111111111111111112',
  icon: 'https://img.raydium.io/icon/So11111111111111111111111111111111111111112.png',
  decimals: 6,
}

export const starsDefaultToken = {
  label: 'StarLaunch',
  name: 'Starlaunch',
  key: 'STARS',
  value: 'HCgybxq5Upy8Mccihrp7EsmwwFqYZtrHrsmsKwtGXLgW',
  icon: 'https://img.raydium.io/icon/HCgybxq5Upy8Mccihrp7EsmwwFqYZtrHrsmsKwtGXLgW.png',
  decimals: 6,
}

export const usdcDefaultToken = {
  label: 'USD Coin',
  name: 'USD Coin',
  key: 'USDC',
  value: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
  icon: 'https://img.raydium.io/icon/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v.png',
  decimals: 6,
}


export const hydrazineDefaultToken = {
  "value": "4q5UBXJxE91BZKX548qhU8i5QBWvZdXzS3RZwfTgLQda",
  "decimals": 6,
  "extensions": {},
  "icon": "https://img.raydium.io/icon/4q5UBXJxE91BZKX548qhU8i5QBWvZdXzS3RZwfTgLQda.png",
  "label": "HYDRAZINE",
  "name": "HYDRAZINE",
  "key": "N2H4"
}
export const NO_TOKENS_RESPONSE = 'NO_TOKENS_FOUND';
