import * as CONSTANTS from "../constants/constants";

let env = CONSTANTS.PRODUCTION;
export const getEnvironment = () => {
  const { host } = window.location;
  // if (
  //   host.indexOf("localhost") !== -1 ||
  //   host.indexOf("dev-stars.starlaunch.com") !== -1
  // ) {
   // env = CONSTANTS.DEVELOPMENT;
  // }
  return env;
}

getEnvironment(); // Update Environment

export const doesURLInclude = (href) => {
  return window.location.href.includes(href);
}

export const getApiUrl = () => {
    return env === CONSTANTS.PRODUCTION ? CONSTANTS.PROD_API_URL : CONSTANTS.DEV_API_URL;
};

export const getRpcUrl = () => {
    return env === CONSTANTS.PRODUCTION ? CONSTANTS.PROD_RPC_NODE_URL : CONSTANTS.DEV_RPC_NODE_URL;
};

export const getVaultProgramName = () => {
    return env === CONSTANTS.PRODUCTION ? CONSTANTS.PROD_VAULT_PROGRAM_NAME : CONSTANTS.DEV_VAULT_PROGRAM_NAME;
}

export const getIdoProgramName = () => {
    return env === CONSTANTS.PRODUCTION ? CONSTANTS.PROD_IDO_PROGRAM_NAME : CONSTANTS.DEV_IDO_PROGRAM_NAME;
}

export const getNodeIdoProgramName = () => {
    return env === CONSTANTS.PRODUCTION ? CONSTANTS.PROD_NODE_IDO_PROGRAM_NAME : CONSTANTS.DEV_NODE_IDO_PROGRAM_NAME;
}



export const openURL = (url) => {
    window.open(url);
};
export const KYC_STATUS_PENDING = 0;
export const KYC_STATUS_APPROVED = 1;
export const KYC_STATUS_NOT_APPROVED = 2;
export const FRACTAL_LINK = 'https://fractal.id/authorize?client_id=b5kR1YtTwLjdoWNkat9gxgj8HEKZIIj8pDBgnpCIVY0&redirect_uri=https%3A%2F%2Fapp.starlaunch.com%2F&response_type=code&scope=contact%3Aread%20verification.plus%3Aread%20verification.plus.details%3Aread%20verification.liveness%3Aread%20verification.liveness.details%3Aread%20verification.wallet-sol:read%20verification.wallet-sol.details:read%20verification.telegram:read%20verification.telegram.details:read'
