import React, {useEffect, useRef, useState} from 'react';
import styles from "./UserAdministration.module.scss";
import {Tab, Tabs} from "@mui/material";
import { hasValue} from "../../helpers/helperFunctions";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import {DataGrid} from "@mui/x-data-grid";
import TextInput from "../../Components/TextInput/TextInput";
import EditCadetModal from "./EditCadetModal";
import EditAdminModal from "./EditAdminModal";
import Button, {BUTTON_TYPE_SECONDARY} from "../../Components/Button/Button";
import {PROD_API_URL} from "../../constants/constants";

const adminWalletColumns = [
  { field: 'solanaWallet', headerName: 'Wallet', flex: 2},
  { field: 'name', headerName: 'Name', flex: 0.5},
  { field: 'canViewDashboard', headerName: 'Access Admin Dash', flex: 0.5},
  { field: 'canEditIdo', headerName: 'Edit IDO Data', flex: 0.5 },
  { field: 'isAdmin', headerName: 'Administrator', flex: 0.5 },
];

const cadetWalletColumns =
  [
    { field: 'cadetId', headerName: 'Cadet ID', flex: 1},
    { field: 'solanaWallet', headerName: 'SOL Wallet', flex: 3},
    { field: 'ethereumWallet', headerName: 'ETH Wallet', flex: 1},
    { field: 'polygonWallet', headerName: 'Polygon Wallet', flex: 1},
    { field: 'arbitrumWallet', headerName: 'Arbitrum Wallet', flex: 1},
    { field: 'supraWallet', headerName: 'SUPRA Wallet', flex: 1},
    { field: 'suiWallet', headerName: 'SUI Wallet', flex: 1},
    { field: 'avalancheWallet', headerName: 'Avalanche Wallet', flex: 1},
    { field: 'dymensionWallet', headerName: 'Dymension Wallet', flex: 1},
  ]

const TAB_CADET_ADMIN = 0;
const TAB_ADMIN_WALLET = 1;

function UserAdministration() {
  const [adminWalletRows, setAdminWalletRows] = useState();
  const [cadetWalletRows, setCadetWalletRows] = useState();
  const [cadetWalletStatistics, setCadetWalletStatistics] = useState()
  const [tab, setTab] = useState(TAB_CADET_ADMIN);
  const [cadetFilter, setCadetFilter] = useState();
  const [editModalData, setEditModalData] = useState();
  const [editAdminModalData, setEditAdminModalData] = useState();

  const tableRef = useRef();

  const fetchAdminWallets = async () => {
    fetch(PROD_API_URL+"/adminWallets/", {
      "method": "GET",
      "headers": {"content-type": "application/json", "accept": "*"},
    }).then(res=>res.json())
      .then((result)=>{
        setAdminWalletRows(result.data)

      });
  }

  const fetchCadetWallets = async () => {
    fetch(PROD_API_URL+"/cadetInfo/", {
      "method": "GET",
      "headers": {"content-type": "application/json", "accept": "*"},
    }).then(res=>res.json())
      .then((result)=>{
        calculateStatistics(result.data);
        setCadetWalletRows(result.data)

      });
  }

  useEffect( ()=>{

    fetchCadetWallets();
    fetchAdminWallets();
  },[]);

  const calculateStatistics = (rows) => {

    const finalResult = [];
    const keyBlacklist = ['createdAt', 'updatedAt', 'id', 'cadetId', 'solanaWallet']

    rows.forEach(row => {
      const deconstructed = Object.entries(row);
      deconstructed.forEach(value => {
        const walletKey = value[0];
        const walletValue = value[1];
        if (hasValue(walletValue) && !keyBlacklist.includes(walletKey)) {
          const foundIndex = finalResult[walletKey];
          if (foundIndex) {
            finalResult[walletKey] = finalResult[walletKey] + 1;
          } else {
            finalResult[walletKey] = 1
          }
        }
      })

    })

    setCadetWalletStatistics(finalResult);
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleFilter = (value) => {
    if (cadetWalletRows) {
      const filteredCadetWallet = cadetWalletRows?.filter(row => row.solanaWallet.includes(value));
      setCadetWalletRows(filteredCadetWallet);
    }
    setCadetFilter(value);
  }

  return (
    <main id="home" key="home" className={styles.mainIdoContainer}>
       <div className={styles.userAdminContainer}>
           <div className={styles.userAdministrationSwitch}>
             <Tabs
             centered
             value={tab}
             onChange={handleTabChange}
             aria-label="basic tabs example">
             <Tab label="Cadet Wallets Administration" {...a11yProps(TAB_CADET_ADMIN)} />
             <Tab label="Admin Wallet Administration" {...a11yProps(TAB_ADMIN_WALLET)} />
           </Tabs>
           </div>
           { !adminWalletRows && tab === TAB_ADMIN_WALLET && <LoadingSpinner local />}
           { adminWalletRows && tab === TAB_ADMIN_WALLET &&
             <>
               <div className={styles.cadetWalletToolbar}>
                 <Button
                   buttonType={BUTTON_TYPE_SECONDARY}
                   text="Add new admin wallet"
                   onClick={() => setEditAdminModalData({})} />
               </div>
               <br/>
               <div className={styles.idoTableContainer}>
                 <DataGrid
                   apiRef={tableRef}
                   rows={adminWalletRows}
                   onCellDoubleClick={(params) => setEditAdminModalData(params.row)}
                   columns={adminWalletColumns}
                   initialState={{
                     pagination: {
                       paginationModel: {page: 1, pageSize: 20},
                     },
                   }}
                   pageSizeOptions={[5, 10, 20]}
                 />
               </div>
             </>

           }
           {!cadetWalletRows && tab === TAB_CADET_ADMIN && <LoadingSpinner local/>}
           {cadetWalletRows && tab === TAB_CADET_ADMIN &&
             <>
               <div className={styles.cadetWalletToolbar}>
                 <div className={styles.filterContainer}>
                   <TextInput label="Filter SOL Wallet" setValue={handleFilter} value={cadetFilter}/>
                 </div>
                 <div className={styles.walletStatistics}>
                   <span>ETHEREUM: {cadetWalletStatistics.ethereumWallet}</span>
                   <span>POLYGON: {cadetWalletStatistics.polygonWallet}</span>
                   <span>ARBITRUM: {cadetWalletStatistics.arbitrumWallet}</span>
                   <span>SUPRA: {cadetWalletStatistics.supraWallet}</span>
                   <span>SUI: {cadetWalletStatistics.suiWallet}</span>
                   <span>AVALANCHE: {cadetWalletStatistics.avalancheWallet}</span>
                  <span>DYMENSION: {cadetWalletStatistics.dymensionWallet}</span>
                </div>
              </div>
             <div className={styles.idoTableContainer}>
               <DataGrid
                 apiRef={tableRef}
                 rows={cadetWalletRows}
                 onCellDoubleClick={(params) => setEditModalData(params.row)}
                 columns={cadetWalletColumns}
                 initialState={{
                   pagination: {
                     paginationModel: { page: 1, pageSize: 10 },
                   },
                 }}
                 pageSizeOptions={[5, 10, 20]}
               />
             </div>

            </>

           }

         </div>
      {editAdminModalData && <EditAdminModal adminData={editAdminModalData} setAdminData={setEditAdminModalData} onComplete={fetchAdminWallets} /> }
      {editModalData && <EditCadetModal cadetData={editModalData} setCadetData={setEditModalData} />}
    </main>

  );
}

export default UserAdministration;
