import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import styles from "./Raffles.module.scss";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {PROD_API_URL} from "../../constants/constants";
import {calculateBlockchain, hasValue} from "../../helpers/helperFunctions";
import moment from "moment/moment";
import {setIdoData} from "../../Components/Header/idoData";
import { useSelector, useDispatch } from "react-redux";
import {useHistory} from "react-router-dom";
import {PATH_IDO_ADMINISTRATION, PATH_RAFFLE_ADMINISTRATION} from "../../App";
import Button, {BUTTON_TYPE_SECONDARY} from "../../Components/Button/Button";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import {DataGrid} from "@mui/x-data-grid";
import toast from "react-hot-toast";
import {errorToast} from "../../helpers/toastCSS";
import {setRaffleData} from "../../Components/Header/raffleData";

const columns = [
  { field: 'name', headerName: 'Name', flex: 1 },
  { field: 'currency', headerName: 'Currency' },
  { field: 'blockchain', headerName: 'Blockchain' },
  { field: 'poolName', headerName: 'Pool Name' },
  { field: 'ticketPrice', headerName: 'Ticket Cost', flex: 0.5 },
  { field: 'totalPriceAmount', headerName: 'Total Price', flex: 0.5 },
  { field: 'production', headerName: 'Production' },
  { field: 'contributionStartTime', headerName: 'Contribution Start', flex: 1},
  { field: 'contributionEndTime', headerName: 'Contribution End', flex: 1},
  { field: 'raffleEndTime', headerName: 'Raffle End', flex: 1},
];

export const NEW_RAFFLE_ID = 'newRaffle'
function Raffles() {
  const dispatch = useDispatch();
  const history = useHistory();
  const adminUser = useSelector(state => state.userData.adminUser)

  const [rows, setRows] = useState();

  const tableRef = useRef();

  useEffect( ()=>{
    const fetchRaffle = async () => {
      fetch(PROD_API_URL +"/raffles/", {
        "method": "GET",
        "headers": {"content-type": "application/json", "accept": "*"},
      }).then(res=>res.json())
        .then((result)=>{
          parseResult(result)
          dispatch(setRaffleData(result.data))
        });
    }

    fetchRaffle();
  },[]);

  const parseResult = (result) => {
    const finalResult = [];

    result.data.forEach(raffle => {
      finalResult.push({
        id: raffle.id,
        name: raffle.name,
        currency: raffle.currency,
        priceType: raffle.priceType,
        poolName: raffle.poolName,
        hydrazineCap: raffle.hydrazineCap,
        ticketPrice: raffle.ticketPrice,
        totalPriceAmount: raffle.totalPriceAmount,
        production: raffle.production ? 'Yes' : 'No',
        contributionStartTime: moment.unix(raffle.contributionStartTime).format('YYYY-MM-DD , h:mm A'),
        contributionEndTime: moment.unix(raffle.contributionEndTime).format('YYYY-MM-DD , h:mm A'),
        raffleEndTime: moment.unix(raffle.raffleEndTime).format('YYYY-MM-DD , h:mm A'),
      })
    })

    setRows(finalResult)
  }

  const redirectToCreateOrEdit = (id) => {
    if (adminUser.canEditRaffle !== true) {
      return toast('You do not have access to edit Raffles', errorToast);
    }

    const location = {
      pathname: `${PATH_RAFFLE_ADMINISTRATION}/${id}`,
    }
    history.push(location);
  }


  return (
    <main id="home" key="home" className={styles.mainIdoContainer}>
       <div className={styles.idoAdminContainer}>
         <div className={styles.idoTableContainer}>
           { !rows && <LoadingSpinner local />}
           { rows &&
             <DataGrid
               apiRef={tableRef}
               rows={rows}
               onCellDoubleClick={(params) => redirectToCreateOrEdit(params.row.id)}
               columns={columns}
               initialState={{
                 pagination: {
                   paginationModel: { page: 1, pageSize: 50 },
                 },
               }}
               pageSizeOptions={[5, 10, 50]}
             />

           }
         </div>
         <div className={styles.idoTableFooter}>
           <Button text="CREATE NEW RAFFLE" buttonType={BUTTON_TYPE_SECONDARY} onClick={() => redirectToCreateOrEdit(NEW_RAFFLE_ID)} />
         </div>
       </div>
    </main>

  );
}

export default Raffles;
