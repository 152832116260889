import React, { useEffect } from "react";
import "./Header.scss";
import { Buffer } from 'buffer';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { AnchorProvider  } from "@coral-xyz/anchor";
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import Leftnav from "../Leftnav/Leftnav";
import {useDispatch} from "react-redux";
import {setAdminUser} from "./userData";
import {PROD_API_URL} from "../../constants/constants";

function Header(props) {
  const {
    setProvider,
    provider,
    authorized,
    setAuthorized,
  } = props;

  const wallet = useWallet();
  const dispatch = useDispatch();
  const { connection } = useConnection();
  
  useEffect(() => {
      setProvider(new AnchorProvider(connection, wallet, AnchorProvider.defaultOptions()));
  }, [wallet.connected]);

  useEffect(() => {
    const fetchAuth = async () => {
      try {
        fetch(PROD_API_URL + "/adminWallet/" + provider.wallet.publicKey.toString(), {
          "method": "GET",
          "headers": { "content-type": "application/json", "accept": "*" },
        }).then(res => res.json())
          .then((result) => {
            console.log(result.data)
            if (result.data.length > 0) {
              const accessToDashboard = result.data[0].canViewDashboard;
              setAuthorized(accessToDashboard === true);
              dispatch(setAdminUser(result.data[0]))
            }

          });
      } catch (e) {
        console.warn("Failed", e);
      }
    }

    if (provider?.wallet?.publicKey) {
      fetchAuth()
    }
  }, [provider?.wallet?.publicKey]);

  return (
    <header key='header'>
      {authorized &&<Leftnav /> }
      <nav
        id="main-navbar"
        className="headerNav"
      >
        <div className="headerContainer">
          <div className="headerLeftContainer">
             

          </div>

          <ul className="navbar-nav ms-auto d-flex flex-row">
            <li className="nav-item me-3 me-lg-0">
              <div className="nav-link"  >
                <WalletMultiButton />
              </div>
            </li>
          </ul>

        </div>
      </nav>
    </header>
  );
}

export default Header;

