import React, {useState} from "react";
import styles from  "./ImageSubmit.module.scss";

function ImageSubmit(props) {
    const {
        text,
        useBase64 = true,
        base64Value,
        setBase64,
    } = props;

    const [imagePreview, setImagePreview] = useState();
    const handleIconSubmit = async (e) => {
        let file = e.target.files[0];
        if (file) {
            const pic = await convertToBase64(file)
            setBase64(pic)
        }
    }

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const photoUpload = (e) => {
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];
        if (reader !== undefined && file !== undefined) {
            reader.onloadend = () => {
                setImagePreview(reader.result)
            }
            reader.readAsDataURL(file);
        }
    }

   if (useBase64) {
       return (
         <div className={styles.imageSubmitContainer} onChange={(e) =>  handleIconSubmit(e)}>
             <span className={styles.title}>{text}</span>
             {(base64Value || imagePreview) && <img src={imagePreview ? imagePreview : base64Value} className={styles.logoImage} alt="Icon"/>}
             <input
                    type="file"
                    name="avatar"
                    id="file"
                    accept=".jpef, .png, .jpg"
                    onChange={photoUpload}
                    src={imagePreview}/>
         </div>
       )
   }

  return (
      <div>

      </div>
  );
}

export default ImageSubmit;
