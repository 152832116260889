import React, {useEffect, useMemo, useState} from 'react';
import styles from "./RaffleCreateOrEdit.module.scss";
import {PROD_API_URL} from "../../constants/constants";
import { useSelector, useDispatch } from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import TextInput from "../../Components/TextInput/TextInput";
import TextAreaInput from "../../Components/TextAreaInput/TextAreaInput";
import {Checkbox, FormControlLabel, MenuItem, TextField} from "@mui/material";
import Button, {BUTTON_TYPE_SECONDARY} from "../../Components/Button/Button";
import {PATH_IDO_ADMINISTRATION, PATH_RAFFLE_ADMINISTRATION} from "../../App";
import DraftEditor from "../../Components/DraftEditor/DraftEditor";
import {NEW_IDO_ID} from "../IdoAdministration/IdoAdministration";
import {
  BLOCKCHAIN_ARBITRUM,
  BLOCKCHAIN_ETH,
  BLOCKCHAIN_POLYGON,
  BLOCKCHAIN_SOLANA,
  hasValue
} from "../../helpers/helperFunctions";
import ImageSubmit from "../../Components/ImageSubmit/ImageSubmit";
import RaffleManagerModal from "../../Components/RaffleManagerModal/RaffleManagerModal";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment/moment";
import toast from "react-hot-toast";
import {errorToast, successToast} from "../../helpers/toastCSS";
import { RaffleErrorHandler } from '../../helpers/errorHelper';
import {NEW_RAFFLE_ID} from "../RaffleAdministration/Raffles";
import TokenModalSelect from "../../Components/TokenModalSelect/TokenModalSelect";
import {
  checkIfRaffleUser,
  createRaffle,
  createRaffleUser,
  NO_TOKENS_RESPONSE,
  solanaDefaultToken,
  updateRaffle,
} from "./raffleHelper";
import {useConnection} from "@solana/wallet-adapter-react";
import { Tooltip } from "react-tooltip";
import { pickWinner } from './raffleHelper';
import {getEnvironment} from "../../helpers/helper";
import * as CONSTANTS from "../../constants/constants";

const blockchains = [
  {
    value: BLOCKCHAIN_SOLANA,
    label: 'Solana',
  },
  {
    value: BLOCKCHAIN_ETH,
    label: 'Ethereum',
  },
  {
    value: BLOCKCHAIN_POLYGON,
    label: 'POLYGON',
  },
  {
    value: BLOCKCHAIN_ARBITRUM,
    label: 'Arbitrum',
  }
]

function RaffleCreateOrEdit(props) {
  const {
    provider
  } = props;
  
  const { id } = useParams();
  
  const { connection } = useConnection();
  const history = useHistory();

  const [selectedRaffle, setSelectedRaffle] = useState();
  const [openDeleteRaffleModal, setOpenDeleteRaffleModal] = useState(false);
  const [openEditorModal, setOpenEditorModal] = useState(false);
  const [raffleTicketToken, setRaffleTicketToken] = useState(solanaDefaultToken);
  const [tokenList, setTokenList] = useState([]);

  const [contributionStartTime, setContributionStartTime] = useState();
  const [contributionEndTime, setContributionEndTime] = useState();
  const [raffleEndTime, setRaffleEndTime] = useState();
  const [bannerImages, setBannerImages] = useState([]);
  const [bannerVideos, setBannerVideos] = useState([]);

  const [icon, setIcon] = useState();
  const [showInProduction, setShowInProduction] = useState(false);
  const [HTMLDescription, setHTMLDescription] = useState();
  const [name, setName] = useState('');
  const [poolName, setPoolName] = useState('');
  const [totalPriceAmount, setTotalPriceAmount] = useState('');
  const [ticketPrice, setTicketPrice] = useState('');
  const [maxTicketUser, setMaxTicketUser] = useState('');
  const [maxTicketRaffle, setMaxTicketRaffle] = useState('');
  const [maxWinners, setMaxWinners] = useState('1')
  const [currency, setCurrency] = useState('');
  const [headerDescription, setHeaderDescription] = useState('');
  const [showManagerModal, setShowManagerModal] = useState(false);
  const [blockchain, setBlockchain] = useState(BLOCKCHAIN_SOLANA);

  const [discord, setDiscord] = useState('');
  const [medium, setMedium] = useState('');
  const [telegram, setTelegram] = useState('');
  const [twitter, setTwitter] = useState('');
  const [website, setWebsite] = useState('');
  

  const rafflesData = useSelector(state => state.raffleData.allRaffles);

  const setIDODefaultData = (raffleData) => {
    if (id === NEW_RAFFLE_ID) {
      return;
    }

    const selectedRaffle = hasValue(raffleData) ? raffleData : rafflesData.find(raffle => raffle.id === id);

    if (!selectedRaffle) return;

    setIcon(selectedRaffle.icon);
    setContributionStartTime(moment.unix(selectedRaffle.contributionStartTime).utc());
    setContributionEndTime(moment.unix(selectedRaffle.contributionEndTime).utc());
    setRaffleEndTime(moment.unix(selectedRaffle.raffleEndTime).utc());
    setBannerImages(selectedRaffle.bannerImages);
    setBannerVideos(selectedRaffle.bannerVideos);

    setShowInProduction(selectedRaffle.showInProduction);
    setHTMLDescription(selectedRaffle.HTMLDescription)
    setName(selectedRaffle.name);
    setPoolName(selectedRaffle.poolName)
    setTotalPriceAmount(selectedRaffle.totalPriceAmount);
    setTicketPrice(selectedRaffle.ticketPrice);
    setMaxTicketUser(selectedRaffle.maxTicketUser);
    setMaxTicketRaffle(selectedRaffle.maxTicketRaffle);
    setRaffleTicketToken(selectedRaffle.raffleTicketToken)
    setCurrency(selectedRaffle.currency);
    setHeaderDescription(selectedRaffle.headerDescription);
    setBlockchain(selectedRaffle.blockchain)

    setDiscord(selectedRaffle.discord);
    setMedium(selectedRaffle.medium);
    setTelegram(selectedRaffle.telegram);
    setTwitter(selectedRaffle.twitter);
    setWebsite(selectedRaffle.website);
    setMaxWinners(selectedRaffle.maxWinners?.toString() || '1');

    setSelectedRaffle(selectedRaffle);
  }

  useEffect( ()=> {
      const fetchRaffleData = async () => {
        fetch(PROD_API_URL+"/raffles/" + id, {
          "method": "GET",
          "headers": {"content-type": "application/json", "accept": "*"},
        }).then(res=>res.json())
          .then((result)=> {
            if (result) {
              setIDODefaultData(result)
            }
          });
    }
    
    if (rafflesData.length === 0) {
      fetchRaffleData();
    } else {
      setIDODefaultData();
    }

  }, [rafflesData]);

  const hasAllMandatoryValues = useMemo(() => {
    let tooltip = '';
    if (!hasValue(raffleTicketToken)) tooltip += ', Token';

    if (!hasValue(name)) tooltip += ', Raffle Name';

    if (!hasValue(poolName)) {
      tooltip += ', Pool Name';
    } else {
      if (poolName.length > 10) tooltip += ', Pool Name has more than 10 characters';
      if (poolName.includes(' ')) tooltip += ', Pool Name cannot include spaces';
    }

    if (!hasValue(maxTicketUser) || Number(maxTicketUser) === 0 ) tooltip += ', Max Ticket Per User';

    if (!hasValue(maxTicketRaffle) || Number(maxTicketRaffle) === 0 ) tooltip += ', Max Ticket Raffle';

    if (!hasValue(ticketPrice) || Number(ticketPrice) === 0 ) tooltip += ', Max Ticket Raffle ';

    if (!hasValue(contributionStartTime)) tooltip += ', Contribution StartTime';

    if (!hasValue(contributionEndTime)) tooltip += ', Contribution EndTime';

    return { disabled: tooltip !== '', tooltip }
  }, [contributionEndTime, contributionStartTime, maxTicketRaffle, maxTicketUser, name, raffleTicketToken, ticketPrice, poolName]);

  const shouldUpdateContract = () => {
    return (
      raffleTicketToken !== selectedRaffle?.raffleTicketToken ||
      poolName !== selectedRaffle?.poolName ||
      maxTicketUser !== selectedRaffle?.maxTicketUser ||
      maxTicketRaffle !== selectedRaffle?.maxTicketRaffle ||
      ticketPrice !== selectedRaffle?.ticketPrice ||
      maxWinners !== selectedRaffle?.maxWinners ||
      moment(contributionStartTime).utc().unix() !== selectedRaffle?.contributionStartTime ||
      moment(contributionEndTime).utc().unix() !== selectedRaffle?.contributionEndTime
    )
  }
  const saveChanges = async (force = false) => {
    try {
      const ticketPriceFormated = Number(ticketPrice) * (10 ** raffleTicketToken.decimals)

      const raffleData =  {
        tokenMint: getEnvironment() === CONSTANTS.PRODUCTION ? raffleTicketToken.value : "H8KpvSniaKpTF4VQxtj2igC5cpvvS3zJgawm3ehGrrPa",
        raffleName: poolName,
        maxTicketUser: Number(maxTicketUser),
        maxWinners: Number(maxWinners),
        maxTicketRaffle: Number(maxTicketRaffle),
        ticketPrice: ticketPriceFormated,
        contributionStartTime: moment(contributionStartTime).utc().unix(),
        contributionEndTime: moment(contributionEndTime).utc().unix(),
      };

      if (hasValue(id) && id === NEW_RAFFLE_ID || force === true) {
        const hasRaffleAccount = await checkIfRaffleUser({ connection, provider });
        if (!hasRaffleAccount) {
          await createRaffleUser({ connection, provider })
        }
        await createRaffle({ connection, provider, raffleData })
      }
      
      //Update raffle
      if (hasValue(id) && id !== NEW_RAFFLE_ID && shouldUpdateContract() ) {
        const hasRaffleAccount = await checkIfRaffleUser({ connection, provider });
        if (!hasRaffleAccount) {
          await createRaffleUser({ connection, provider })
        }

        await updateRaffle({ connection, provider, raffleData });
      }

      let body= {
        "icon": icon,
        "contributionStartTime": hasValue(contributionStartTime) ? moment(contributionStartTime).utc().unix() : selectedRaffle?.contributionStartTime,
        "contributionEndTime": hasValue(contributionEndTime) ? moment(contributionEndTime).utc().unix() : selectedRaffle?.contributionEndTime,
        "raffleEndTime": hasValue(raffleEndTime) ? moment(raffleEndTime).utc().unix() : selectedRaffle?.raffleEndTime,
        "bannerImages": bannerImages,
        "bannerVideos": bannerVideos,
        "production": showInProduction,
        "HTMLDescription": HTMLDescription,
        "name": name,
        "poolName": poolName,
        "totalPriceAmount": totalPriceAmount,
        "ticketPrice": ticketPrice,
        "maxTicketUser": maxTicketUser,
        "maxTicketRaffle": maxTicketRaffle,
        "raffleTicketToken": raffleTicketToken,
        "headerDescription": headerDescription,
        "blockchain": blockchain,
        "currency": currency,
        "website": website,
        "telegram": telegram,
        "discord": discord,
        "medium": medium,
        "twitter": twitter,
        "maxWinners": maxWinners,
      };

      if (hasValue(id) && id !== NEW_RAFFLE_ID) {
        body.id = selectedRaffle.id;
      }

      await fetch(PROD_API_URL + "/raffles/createOrEdit", {
        "method": "POST",
        "headers": {"content-type": "application/json", "accept": "*"},
        "body": JSON.stringify(body)
      }).then(r => {
        if (r.ok && r.status === 200) {
          if (id === NEW_RAFFLE_ID) {
            toast('Raffle Successfully Created!', successToast)
            history.push(PATH_RAFFLE_ADMINISTRATION);
          } else {
            toast('Raffle Successfully updated!', successToast)
          }
        } else {
          toast('ERROR Saving Raffle', errorToast)
        }
      })
    } catch (e) {
      toast(RaffleErrorHandler.handleManagerErrors(e), errorToast);
      console.error(e);
    }

  }

  const deleteRaffle = async () => {
    try {
      await fetch(PROD_API_URL + "/raffles/removeRaffle", {
        "method": "POST",
        "headers": {"content-type": "application/json", "accept": "*"},
        "body": JSON.stringify({ id: selectedRaffle.id})
      }).then(r => {
        if (r.ok && r.status === 200) {
          toast('Raffle Successfully Removed!', successToast)
          history.push(PATH_RAFFLE_ADMINISTRATION);
        } else {
          toast('ERROR removing Raffle', errorToast)
        }
      })
    } catch (e) {
      toast(RaffleErrorHandler.handleManagerErrors(e), errorToast);
    } finally {
      setOpenDeleteRaffleModal(false);
    }
  }

  const handleSetWinners = (value) => {
    const n = Number(value);
    if (n <= 10 && n >= 1) {
        setMaxWinners(value);
    }
};

  const fetchToken = async (value) => {
    return await fetch(`${PROD_API_URL}/token/${value}`)
      .then(r => r.json())
      .then(response => {
        const result = response.data.map(token => {
          return {
            name: token.name,
            key: token.symbol,
            icon: token.icon,
            value:  token.mint,
            decimals: token.decimals,
          };
        })
        setTokenList(prev => {
          return (
            { searchedTokens: response.data.length !== 0 ? result : []}
          )
        })
        if (response.data.length === 0) return NO_TOKENS_RESPONSE;
      })
  }

  const handlePickWinner = async () => {
    if (!selectedRaffle || id === NEW_RAFFLE_ID) {
      toast('No raffle selected', errorToast);
      return;
    }
  
    try {
      await pickWinner({
        provider,
        connection,
        raffleName: poolName
      });

      
      toast(`Success! Winner picked: ${result.winner}`, successToast);
    } catch (error) {
      toast(`Error picking winner: ${error.message}`, errorToast);
    }
  };


  return (
    <main id="home" key="home" className={styles.mainIdoContainer}>
      <Tooltip id={"RaffleTooltip"} effect="solid" place="top" />
      {openEditorModal && <DraftEditor
        setClose={() => setOpenEditorModal(false)}
        setFinishedHtml={setHTMLDescription}
        valueHTML={HTMLDescription} />}
      {(selectedRaffle || id === NEW_RAFFLE_ID) && <div className={styles.idoAdminContainer}>
        <div className={styles.idoInputContainer}>
          <div className={styles.editRow}>
            <TextInput value={name} label="Name*" setValue={setName}/>
            <TextInput value={poolName} label="Pool Name" setValue={setPoolName}/>
            <TextInput value={totalPriceAmount} label="Total Price Amount" setValue={setTotalPriceAmount}/>
            <div className={styles.checkboxContainer}>
              <TextField
                id="outlined-select-currency"
                select
                label="Select"
                defaultValue={blockchain}
                helperText="Select Distribution Blockchain">
                {blockchains.map((option) => (
                  <MenuItem key={option.value} value={option.value} onClick={() => setBlockchain(option.value)}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className={styles.buttonContainer}>
              <ImageSubmit base64Value={icon} setBase64={setIcon} useBase64 text="Raffle Icon"/>
            </div>
          </div>
          <div className={styles.editRow}>

            <div className={styles.checkboxContainer}>
              <FormControlLabel
                control={<Checkbox checked={showInProduction}
                                   onChange={event => setShowInProduction(event.target.checked)}/>}
                label="Show in production"
                labelPlacement="top"
              />
            </div>
            <div className={styles.checkboxContainer}>
              <TextInput type={'number'} value={maxWinners} label="Max Winners*"
                         setValue={handleSetWinners}/>
            </div>
            <div className={styles.checkboxContainer}>
              <TextInput type={'number'} value={ticketPrice} label="Ticket Price*" setValue={setTicketPrice}/>
            </div>

            <div className={styles.tokenModalContainer}>
              <span className={styles.raffleTokenText}>Raffle Ticket Token*</span>
              <TokenModalSelect
                setValue={setRaffleTicketToken}
                value={raffleTicketToken}
                fetchToken={fetchToken}
                options={tokenList}/>
            </div>
            <div className={styles.checkboxContainer}>
              <TextInput type={'number'} value={maxTicketUser} label="Max Ticket Per User*"
                         setValue={setMaxTicketUser}/>
            </div>
            <div className={styles.checkboxContainer}>
              <TextInput type={'number'} value={maxTicketRaffle} label="Max Ticket Total*"
                         setValue={setMaxTicketRaffle}/>
            </div>
          </div>

          <div className={styles.textAreaInputRow}>
            <div style={{flex: '1'}}>
              <TextAreaInput
                value={headerDescription}
                setValue={setHeaderDescription}
                rows={4} l
                label="Short Header Description"/>
            </div>
          </div>

          <TextAreaInput value={bannerImages} setValue={setBannerImages}
                         label="Add Banner Images Links (Comma ',' seperated if multiple)"/>
          <TextAreaInput value={bannerVideos} setValue={setBannerVideos}
                         label="Add Banner Video Links (Comma ',' seperated if multiple)"/>
          <div className={styles.editRow}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                value={contributionStartTime}
                onChange={(value) => setContributionStartTime(value)}
                label="Contribution Start*"/>
              <DateTimePicker
                value={contributionEndTime}
                onChange={setContributionEndTime}
                label="Contribution End*"/>
              <DateTimePicker
                value={raffleEndTime}
                onChange={setRaffleEndTime}
                label="Raffle End"/>
            </LocalizationProvider>

          </div>
          <div className={styles.editRow}>
            <TextInput value={discord} label="Discord" setValue={setDiscord}/>
            <TextInput value={medium} label="Medium" setValue={setMedium}/>
            <TextInput value={telegram} label="Telegram" setValue={setTelegram}/>
          </div>
          <div className={styles.editRow}>
            <TextInput value={twitter} label="Twitter" setValue={setTwitter}/>
            <TextInput value={website} label="Website" setValue={setWebsite}/>
          </div>
        </div>
        <div>
          {hasValue(hasAllMandatoryValues.tooltip) && <div className={styles.missingRaffleValuesContainer}>
            {'MISSING MANDATORY VALUES: ' + hasAllMandatoryValues.tooltip}
          </div>}
          <div className={styles.bottomActionContainer}>
            <Button
              className={styles.deleteButton}
              buttonType={BUTTON_TYPE_SECONDARY}
              disabled={!hasValue(id) || id === NEW_IDO_ID}
              text="DELETE"
              onClick={() => setOpenDeleteRaffleModal(true)}/>
            <div className={styles.flexGrower}/>
            <div style={{marginRight: '64px'}}>
              <Button buttonType={BUTTON_TYPE_SECONDARY} text="Project Information Editor"
                      onClick={() => setOpenEditorModal(true)}/>
            </div>
            <Button
              className={styles.pickWinnerButton} //There is no corresponding CSS at the moment
              buttonType={BUTTON_TYPE_SECONDARY}
              text="PICK WINNER"
              onClick={handlePickWinner}
              disabled={!selectedRaffle || id === NEW_RAFFLE_ID}
/>
            <Button 
              buttonType={BUTTON_TYPE_SECONDARY} 
              text="Manage Editors" 
              onClick={() => setShowManagerModal(true)}
              disabled={id === NEW_RAFFLE_ID}
            />
            <Button buttonType={BUTTON_TYPE_SECONDARY} text="Reset" onClick={() => setIDODefaultData(selectedRaffle)}/>
            <Button
              className={styles.forceButton}
              buttonType={BUTTON_TYPE_SECONDARY}
              text="Force Contract Creation"
              onClick={() => saveChanges(true)}
              disabled={hasAllMandatoryValues.disabled}/>
            <Button
              buttonType={BUTTON_TYPE_SECONDARY}
              text="Save Changes"
              onClick={saveChanges}
              disabled={hasAllMandatoryValues.disabled}/>
          </div>

        </div>
      </div>}

      {openDeleteRaffleModal && <div className={styles.deleteIdoModalOverlay}>
        <div className={styles.deleteIdoModal}>
          <span>Are you sure you want to delete this Raffle?</span>
          <div className={styles.deleteRow}>
            <Button buttonType={BUTTON_TYPE_SECONDARY} text="CANCEL"
                    onClick={() => setOpenDeleteRaffleModal(false)}/>
            <Button
              className={styles.deleteButton}
              buttonType={BUTTON_TYPE_SECONDARY}
              text="DELETE"
              onClick={deleteRaffle}/>
          </div>
        </div>
      </div>}
      {showManagerModal && (
        <RaffleManagerModal
          show={showManagerModal}
          setShow={setShowManagerModal}
          provider={provider}
          connection={connection}
          raffleData={{
            poolName: poolName
          }}
        />
      )}
    </main>

  );
}

export default RaffleCreateOrEdit;
