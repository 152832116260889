import React, { useEffect, useMemo } from 'react';
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { BackpackWalletAdapter } from "@solana/wallet-adapter-backpack";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-phantom";
import { Provider } from 'react-redux'
import store from './store'
import {
  WalletModalProvider, WalletMultiButton,
} from '@solana/wallet-adapter-react-ui';
import {
  Switch,
  Route
} from "react-router-dom";

import "./App.css";
import Header from "./Components/Header/Header.jsx";
import Home from "./screens/Home/Home.jsx";
import IdoAdministration from "./screens/IdoAdministration/IdoAdministration.jsx";

import { Toaster } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import {getApiUrl, getRpcUrl, doesURLInclude} from './helpers/helper';
import { SolflareWalletAdapter } from '@solana/wallet-adapter-solflare';
import IdoCreateOrEdit from "./screens/IdoCreateOrEdit/IdoCreateOrEdit";
import StakerList from "./screens/StakerList/StakerList";
import {PROD_API_URL} from "./constants/constants";
import InfoCard from "./Components/InfoCard/InfoCard";
import WalletNotConnectedIcon from './images/svg/walletNotConnected.svg';
import UserAdministration from "./screens/UserAdministration/UserAdministration";
import Raffles from "./screens/RaffleAdministration/Raffles";
import ProjectInterest from "./screens/ProjectInterestAdministration/ProjectInterest";
import RaffleCreateOrEdit from "./screens/RaffleEdit/RaffleCreateOrEdit";

// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css');

export const PATH_HOME = '/';
export const PATH_IDO_ADMINISTRATION = '/idos';
export const PATH_EDIT_IDO = '/idos/:id';
export const PATH_STAKERS_LIST = '/staker-list';
export const PATH_USER_ADMINISTRATION = '/users';
export const PATH_RAFFLE_ADMINISTRATION = '/raffle';
export const PATH_EDIT_RAFFLE = '/raffle/:id';
export const PATH_PROJECT_INTEREST = '/projectInterest'




function App() {
  const location = useLocation();
  const network = WalletAdapterNetwork.Mainnet;
  const endpoint = getRpcUrl();
  const wallets = useMemo(() => [
    new PhantomWalletAdapter(),
    new BackpackWalletAdapter(),
    new SolflareWalletAdapter()
  ], [network]);

  const [provider, setProvider] = useState();
  const [authorized, setAuthorized] = useState(false);

  return (
    <CacheBuster currentVersion={packageInfo.version} isEnabled>
      <ConnectionProvider
        endpoint={endpoint}
      >
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            <Provider store={store}>
              <Header setProvider={setProvider} provider={provider} authorized={authorized} setAuthorized={setAuthorized} />
              {!authorized && <InfoCard
                text={provider?.wallet.publicKey == null ? connectWalletText() : connectNotAuthorizedWalletText() }
                title={connectWalletTitle()}
                icon={WalletNotConnectedIcon} />}
              {authorized && <Switch location={location} key={location.pathname}>
                  <Route path={PATH_HOME} exact component={Home} />
                  <Route path={PATH_IDO_ADMINISTRATION} exact component={IdoAdministration} />
                  <Route path={PATH_EDIT_IDO} component={IdoCreateOrEdit} />
                  <Route path={PATH_STAKERS_LIST} exact component={StakerList} />
                  <Route path={PATH_USER_ADMINISTRATION} exact component={UserAdministration} />
                  <Route path={PATH_RAFFLE_ADMINISTRATION} exact component={Raffles} />
                  <Route path={PATH_EDIT_RAFFLE} exact>
                    <RaffleCreateOrEdit provider={provider} />
                  </Route>
                  <Route path={PATH_PROJECT_INTEREST} exact component={ProjectInterest} />
              </Switch>}
              <Toaster />
            </Provider>
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </CacheBuster>
  );
}

export default App;

export const connectWalletTitle = () => {
  return (
    <h5>
      Connect authorized wallet
    </h5>
  );
}

export const connectWalletText = () => {
  return (
    <>
      <p>
        To enter this page you need to connect your authorized Solana wallet. Please use the Select wallet button to connect.
      </p>
      <div className='connectWalletModalWrapper'>
        <WalletMultiButton/>
      </div>
    </>

  );
}

export const connectNotAuthorizedWalletText = () => {
  return (
    <>
      <p>
       This wallet is not authorized, please switch wallet or contact support
      </p>
      <div className='connectWalletModalWrapper'>
        <WalletMultiButton/>
      </div>
    </>

  );
}

