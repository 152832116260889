import { createSlice } from '@reduxjs/toolkit'

export const statistics = createSlice({
    name: 'statistics',
    initialState: {
        stakers: [],
    },
    reducers: {
        setStakers: (state, data) => {
            state.stakers = data.payload;
        }
    },
})

export const {
    setStakers,
    } = statistics.actions

export default statistics.reducer
