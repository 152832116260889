import React, { useEffect, useRef, useState } from "react";
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import styles from "./DraftEditor.module.scss";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Button, {BUTTON_TYPE_PRIMARY, BUTTON_TYPE_SECONDARY} from "../Button/Button";


const DraftEditor = (props) => {
  const {
    setClose,
    valueHTML,
    setFinishedHtml
  } = props;

  const [editorState, setEditorState] = useState(
    EditorState.createEmpty()
  );

  useEffect(() => {
    if (valueHTML) {
      setEditorState(EditorState.createWithContent(convertFromRaw(valueHTML)))
    }
  }, []);

  const saveHTML = () => {
    setClose();
    setFinishedHtml(convertToRaw(editorState.getCurrentContent()));
  }

  return (
    <div className={styles.draftEditorContainer}>
      <div className={styles.editorWrapper}>
        <Editor
          placeholder="Write Here"
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          editorClassName={styles.draftInnerWrapper}
          onEditorStateChange={(editorState) => {
            setEditorState(editorState);
          }}
        />
        <div className={styles.editorFooter}>
          <Button buttonType={BUTTON_TYPE_SECONDARY} text="CANCEL" onClick={setClose} />
          <Button buttonType={BUTTON_TYPE_SECONDARY} text="SAVE HTML" onClick={saveHTML} />

        </div>
      </div>
    </div>
  );
};

export default DraftEditor;
