import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import styles from "./IdoAdministration.module.scss";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {PROD_API_URL} from "../../constants/constants";
import {calculateBlockchain, hasValue} from "../../helpers/helperFunctions";
import moment from "moment/moment";
import {setIdoData} from "../../Components/Header/idoData";
import { useSelector, useDispatch } from "react-redux";
import {useHistory} from "react-router-dom";
import {PATH_IDO_ADMINISTRATION} from "../../App";
import Button, {BUTTON_TYPE_SECONDARY} from "../../Components/Button/Button";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import {DataGrid} from "@mui/x-data-grid";
import toast from "react-hot-toast";
import {errorToast} from "../../helpers/toastCSS";

const columns = [
  { field: 'name', headerName: 'Name', flex: 1 },
  { field: 'status', headerName: 'Status', flex: 0.5},
  { field: 'isNodeSale', headerName: 'Node Sale' },
  { field: 'hasPool', headerName: 'Pool Created' },
  { field: 'pair', headerName: 'Pair', flex: 0.5 },
  { field: 'dollarAmount', headerName: 'Dollar Amount', flex: 0.5 },
  { field: 'symbol', headerName: 'Symbol' },
  { field: 'blockchain', headerName: 'Blockchain', flex: 0.5},
  { field: 'production', headerName: 'Production' },
  { field: 'registrationStart', headerName: 'Registration Start', flex: 1},
];

export const NEW_IDO_ID = 'newIDO'
function IdoAdministration() {
  const dispatch = useDispatch();
  const history = useHistory();
  const adminUser = useSelector(state => state.userData.adminUser)

  const [rows, setRows] = useState();

  const tableRef = useRef();

  useEffect( ()=>{
    const fetchSaleData = async () => {
      fetch(PROD_API_URL+"/sales/", {
        "method": "POST",
        "headers": {"content-type": "application/json", "accept": "*"},
      }).then(res=>res.json())
        .then((result)=>{
          dispatch(setIdoData(result.data.sales_json.sales))
          parseResult(result);

          setTimeout(() => tableRef.current.autosizeColumns({
            includeOutliers: true,
            includeHeaders: true,
          }), 100)
        });
    }

    fetchSaleData();
  },[]);

  const parseResult = (result) => {
    const finalResult = [];



    result.data.sales_json.sales.forEach(ido => {
      const blockchain = calculateBlockchain(ido);
      finalResult.push({
        id: ido.id,
        name: ido.name,
        status: ido.status,
        isNodeSale: ido.isNodeSale ? 'Yes' : 'No',
        hasPool: hasValue(ido.poolname) ? 'Yes' : 'No',
        pair: ido.pair,
        dollarAmount: ido.dollarAmount,
        symbol: ido.symbol,
        blockchain: blockchain,
        production: ido.production ? 'Yes' : 'No',
        registrationStart: moment.unix(ido.registrationStartTime).format('YYYY-MM-DD , h:mm A')
      })
    })

    setRows(finalResult)
  }

  const redirectToCreateOrEdit = (id) => {
    if (adminUser.canEditIdo !== true) {
      return toast('You do not have access to edit IDOs', errorToast);
    }

    const location = {
      pathname: `${PATH_IDO_ADMINISTRATION}/${id}`,
    }
    history.push(location);
  }


  return (
    <main id="home" key="home" className={styles.mainIdoContainer}>
       <div className={styles.idoAdminContainer}>
         <div className={styles.idoTableContainer}>
           { !rows && <LoadingSpinner local />}
           { rows &&
             <DataGrid
               apiRef={tableRef}
               rows={rows}
               onCellDoubleClick={(params) => redirectToCreateOrEdit(params.row.id)}
               columns={columns}
               initialState={{
                 pagination: {
                   paginationModel: { page: 1, pageSize: 50 },
                 },
               }}
               pageSizeOptions={[5, 10, 50]}
             />

           }
         </div>
         <div className={styles.idoTableFooter}>
           <Button text="CREATE NEW IDO" buttonType={BUTTON_TYPE_SECONDARY} onClick={() => redirectToCreateOrEdit(NEW_IDO_ID)} />
         </div>
       </div>
    </main>

  );
}

export default IdoAdministration;
