import React, {useState} from "react";
import styles from './EditCadetModal.module.scss';
import {PROD_API_URL} from "../../constants/constants";
import toast from "react-hot-toast";
import {errorToast, successToast} from "../../helpers/toastCSS";
import TextInput from "../../Components/TextInput/TextInput";
import Button, {BUTTON_TYPE_SECONDARY} from "../../Components/Button/Button";
import {hasValue} from "../../helpers/helperFunctions";
import {Checkbox, FormControlLabel} from "@mui/material";
import {useSelector} from "react-redux";

function EditCadetModal(props) {
    const {
        adminData,
        setAdminData,
        onComplete,
    } = props;

  const [solanaWallet, setSolanaWallet] = useState('');
  const [name, setName] = useState(adminData.name);
  const [canViewDashboard, setCanViewDashboard] = useState(adminData.canViewDashboard);
  const [canEditIdo, setCanEditIdo] = useState(adminData.canEditIdo);
  const [canEditRaffle, setCanEditRaffle] = useState(adminData.canEditRaffle);
  const [isAdmin, setIsAdmin] = useState(adminData.isAdmin);

  const currentUser = useSelector(state => state.userData.adminUser)

  const closeModal = () => {
    setAdminData(null);
  }

  const saveCadetData = async () => {
    const resp = await fetch(PROD_API_URL + '/adminWallets/createOrEdit', {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "accept": "*",
        'Cookie': 'sails.sid=s%3Avh-tedPOHnJh8E0RhjeC820oxrGU6DwJ.5AwSIniluWQcyz%2B6f7UJ8fDteBEX08UIFEXBltW6tfc'
      },
      body: JSON.stringify({
        solanaWallet: hasValue(adminData.solanaWallet) ? adminData.solanaWallet : solanaWallet,
        name,
        canViewDashboard,
        canEditIdo,
        canEditRaffle,
        isAdmin,
      }),
    });

    if (resp.ok) {
      toast('Admin Wallet Created/Updated!', successToast)
      onComplete();
    } else {
      toast('Saving Admin Wallet failed', errorToast)
    }
    closeModal();
  }

  return (
      <div className={styles.editCadetOverlay}>
        <div className={styles.editCadetContainer}>
          {hasValue(adminData.solanaWallet) ? <h4>SOL WALLET: {adminData?.solanaWallet}</h4> :
            <TextInput value={solanaWallet} setValue={setSolanaWallet} label="SOLANA WALLET"/>}
          <TextInput value={name} setValue={setName} label="NAME"/>
          <div className={styles.checkboxContainer}>
            <FormControlLabel
              control={<Checkbox checked={canViewDashboard}
                                 onChange={event => setCanViewDashboard(event.target.checked)}/>}
              label="View Admin Dashboard"
              labelPlacement="Top"
            />
          </div>
          <div className={styles.checkboxContainer}>
            <FormControlLabel
              control={<Checkbox checked={canEditIdo}
                                 onChange={event => setCanEditIdo(event.target.checked)}/>}
              label="Edit IDO"
              labelPlacement="Top"
            />
          </div>
          <div className={styles.checkboxContainer}>
            <FormControlLabel
              control={<Checkbox checked={canEditRaffle}
                                 onChange={event => setCanEditRaffle(event.target.checked)}/>}
              label="Edit Raffle"
              labelPlacement="Top"
            />
          </div>
          <div className={styles.checkboxContainer}>
            <FormControlLabel
              control={<Checkbox checked={isAdmin}
                                 onChange={event => setIsAdmin(event.target.checked)}/>}
              label="Admin"
              disabled={!currentUser?.isAdmin}
              labelPlacement="Top"
            />
          </div>
          <div className={styles.editCadetFooter}>
            <Button text="CANCEL" onClick={closeModal} buttonType={BUTTON_TYPE_SECONDARY}/>
            <Button text="SUBMIT CHANGES" onClick={saveCadetData} buttonType={BUTTON_TYPE_SECONDARY}/>
          </div>
        </div>
      </div>
  );
}

export default EditCadetModal;
