// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
  position: absolute;
  z-index: 10;
  top: 0;
}

.a_wMplDViceCnWMVwMt0 {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 1rem 1rem 0 1rem; /* Height of navbar */
  z-index: 600;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  background: rgba(0, 48, 73, 0.85);
}
@media (max-height: 900px) {
  .a_wMplDViceCnWMVwMt0 {
    top: 1rem;
  }
}
@media (max-height: 800px) {
  .a_wMplDViceCnWMVwMt0 {
    top: 0rem;
  }
}
@media (max-width: 1000px) {
  .a_wMplDViceCnWMVwMt0 {
    display: none !important;
  }
}

.xPadezZ7J7LFahpSNMKT {
  width: 120p;
  height: 30px;
  padding: 8px;
  color: white;
  border-radius: 4px;
  background: rgba(72, 202, 228, 0.25);
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}
.xPadezZ7J7LFahpSNMKT:hover {
  outline: 1px solid white;
}`, "",{"version":3,"sources":["webpack://./src/Components/Leftnav/Leftnav.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,MAAA;AACF;;AAEA;EACI,eAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,yBAAA,EAAA,qBAAA;EACA,YAAA;EACF,aAAA;EACA,sBAAA;EACA,aAAA;EACA,iCAAA;AACF;AAAI;EAXJ;IAYM,SAAA;EAGJ;AACF;AAFI;EAdJ;IAeM,SAAA;EAKJ;AACF;AAJI;EAjBJ;IAkBM,wBAAA;EAOJ;AACF;;AAHA;EACE,WAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,oCAAA;EACA,aAAA;EACA,uBAAA;EACA,eAAA;EACA,mBAAA;AAMF;AALE;EACE,wBAAA;AAOJ","sourcesContent":["header {\r\n  position: absolute;\r\n  z-index: 10;\r\n  top:0;\r\n}\r\n\r\n.navigationBar {\r\n    position: fixed;\r\n    top: 0;\r\n    bottom: 0;\r\n    left: 0;\r\n    padding: 1rem 1rem 0 1rem; /* Height of navbar */\r\n    z-index: 600;\r\n  display: flex;\r\n  flex-direction: column;\r\n  row-gap: 32px;\r\n  background: rgba(0, 48, 73, 0.85);\r\n    @media (max-height: 900px) {\r\n      top: 1rem;\r\n    }\r\n    @media (max-height: 800px) {\r\n      top: 0rem;\r\n    }\r\n    @media (max-width: 1000px) {\r\n      display: none !important;\r\n    }\r\n  }\r\n\r\n\r\n.navigationItem {\r\n  width: 120p;\r\n  height: 30px;\r\n  padding: 8px;\r\n  color: white;\r\n  border-radius: 4px;\r\n  background: rgba(72, 202, 228, 0.25);\r\n  display: flex;\r\n  justify-content: center;\r\n  cursor: pointer;\r\n  align-items: center;\r\n  &:hover {\r\n    outline: 1px solid white;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigationBar": `a_wMplDViceCnWMVwMt0`,
	"navigationItem": `xPadezZ7J7LFahpSNMKT`
};
export default ___CSS_LOADER_EXPORT___;
