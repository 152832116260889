// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OSmp0S3bHqFWwtXb1IuN {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.V954HvijW8g0C0aO_Fp_ {
  width: 80%;
  height: 80%;
  padding: 2rem;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mBcXOTqkADwBc6dHx3NT {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 32px;
}

.em6BsDHdnztDg_wBDYIM {
  max-height: 65vh;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/Components/DraftEditor/DraftEditor.module.scss"],"names":[],"mappings":"AACA;EACE,eAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AAAF;;AAGA;EACE,UAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;AAAF;;AAGA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;AAAF;;AAGA;EACE,gBAAA;EACA,gBAAA;AAAF","sourcesContent":["\n.draftEditorContainer {\n  position: fixed;\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 9999;\n}\n\n.editorWrapper {\n  width: 80%;\n  height: 80%;\n  padding: 2rem;\n  background-color: white;\n  border-radius: 8px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n\n.editorFooter {\n  width: 100%;\n  height: 50px;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  column-gap: 32px;\n}\n\n.draftInnerWrapper {\n  max-height: 65vh;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"draftEditorContainer": `OSmp0S3bHqFWwtXb1IuN`,
	"editorWrapper": `V954HvijW8g0C0aO_Fp_`,
	"editorFooter": `mBcXOTqkADwBc6dHx3NT`,
	"draftInnerWrapper": `em6BsDHdnztDg_wBDYIM`
};
export default ___CSS_LOADER_EXPORT___;
