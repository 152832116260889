import React, {useState} from "react";
import styles from './EditCadetModal.module.scss';
import TextInput from "../../Components/TextInput/TextInput";
import Button, {BUTTON_TYPE_SECONDARY} from "../../Components/Button/Button";
import {PROD_API_URL} from "../../constants/constants";
import toast from "react-hot-toast";
import {errorToast, successToast} from "../../helpers/toastCSS";

function EditCadetModal(props) {
    const {
        cadetData,
        setCadetData
    } = props;

  const [ethereumWalletInput, setEthereumWalletInput] = useState(cadetData.ethereumWallet);
  const [polygonWalletInput, setPolygonWalletInput] = useState(cadetData.polygonWallet);
  const [arbitrumWalletInput, setArbitrumWalletInput] = useState(cadetData.arbitrumWallet);
  const [supraWalletInput, setSupraWalletInput] = useState(cadetData.supraWallet);
  const [suiWalletInput, setSUIWalletInput] = useState(cadetData.suiWallet);
  const [avalancheWalletInput, setAvalancheWalletInput] = useState(cadetData.avalancheWallet);
  const [dymensionWalletInput, setDymensionWalletInput] = useState(cadetData.dymensionWallet);

  const closeModal = () => {
    setCadetData(null);
  }

  const saveCadetData = async () => {
    const resp = await fetch(PROD_API_URL + '/cadetInfo', {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "accept": "*",
        'Cookie': 'sails.sid=s%3Avh-tedPOHnJh8E0RhjeC820oxrGU6DwJ.5AwSIniluWQcyz%2B6f7UJ8fDteBEX08UIFEXBltW6tfc'
      },
      body: JSON.stringify({
        cadetId: cadetData.cadetId,
        solanaWallet: cadetData.solanaWallet,
        ethereumWallet: ethereumWalletInput,
        polygonWallet: polygonWalletInput,
        arbitrumWallet: arbitrumWalletInput,
        supraWallet: supraWalletInput,
        suiWallet: suiWalletInput,
        avalancheWallet: avalancheWalletInput,
        dymensionWallet: dymensionWalletInput,
      }),
    });

    if (resp.ok) {
      toast('Cadet Wallet Updated!', successToast)
    } else {
      toast('Saving Cadet Wallet failed', errorToast)
    }
    closeModal();
  }

  return (
      <div className={styles.editCadetOverlay}>
        <div className={styles.editCadetContainer}>
          <h4>SOL WALLET: {cadetData?.solanaWallet}</h4>
          <TextInput value={ethereumWalletInput} setValue={setEthereumWalletInput} label="ETHEREUM WALLET" />
          <TextInput value={polygonWalletInput} setValue={setPolygonWalletInput} label="POLYGON WALLET" />
          <TextInput value={arbitrumWalletInput} setValue={setArbitrumWalletInput} label="ARBITRUM WALLET" />
          <TextInput value={supraWalletInput} setValue={setSupraWalletInput} label="SUPRA WALLET" />
          <TextInput value={suiWalletInput} setValue={setSUIWalletInput} label="SUI WALLET" />
          <TextInput value={avalancheWalletInput} setValue={setAvalancheWalletInput} label="AVALANCHE WALLET" />
          <TextInput value={dymensionWalletInput} setValue={setDymensionWalletInput} label="DYMENSION WALLET" />
          <div className={styles.editCadetFooter}>
            <Button text="CANCEL" onClick={closeModal} buttonType={BUTTON_TYPE_SECONDARY} />
            <Button text="SUBMIT CHANGES" onClick={saveCadetData}  buttonType={BUTTON_TYPE_SECONDARY} />
          </div>
        </div>
      </div>
  );
}

export default EditCadetModal;
